import A_Forms from "../../../../img/WordPhotos/WordForms/A_Forms.png";
import B_Forms from "../../../../img/WordPhotos/WordForms/B_Forms.png";
import C_Forms from "../../../../img/WordPhotos/WordForms/C_Forms.png";
import D_Forms from "../../../../img/WordPhotos/WordForms/D_Forms.png";
import E_Forms from "../../../../img/WordPhotos/WordForms/E_Forms.png";
import F_Forms from "../../../../img/WordPhotos/WordForms/F_Forms.png";
import G_Forms from "../../../../img/WordPhotos/WordForms/G_Forms.png";
import H_Forms from "../../../../img/WordPhotos/WordForms/H_Forms.png";
import I_Forms from "../../../../img/WordPhotos/WordForms/I_Forms.png";
import J_Forms from "../../../../img/WordPhotos/WordForms/J_Forms.png";
import K_Forms from "../../../../img/WordPhotos/WordForms/K_Forms.png";
import L_Forms from "../../../../img/WordPhotos/WordForms/L_Forms.png";
import M_Forms from "../../../../img/WordPhotos/WordForms/M_Forms.png";
import N_Forms from "../../../../img/WordPhotos/WordForms/N_Forms.png";
import O_Forms from "../../../../img/WordPhotos/WordForms/O_Forms.png";
import P_Forms from "../../../../img/WordPhotos/WordForms/P_Forms.png";
import Q_Forms from "../../../../img/WordPhotos/WordForms/Q_Forms.png";
import R_Forms from "../../../../img/WordPhotos/WordForms/R_Forms.png";
import S_Forms from "../../../../img/WordPhotos/WordForms/S_Forms.png";
import T_Forms from "../../../../img/WordPhotos/WordForms/T_Forms.png";
import U_Forms from "../../../../img/WordPhotos/WordForms/U_Forms.png";
import V_Forms from "../../../../img/WordPhotos/WordForms/V_Forms.png";
import W_Forms from "../../../../img/WordPhotos/WordForms/W_Forms.png";
import X_Forms from "../../../../img/WordPhotos/WordForms/X_Forms.png";
import Y_Forms from "../../../../img/WordPhotos/WordForms/Y_Forms.png";
import Z_Forms from "../../../../img/WordPhotos/WordForms/Z_Forms.png";


import Aranha from "../../../../sounds/phraseSound/A_Aranha.mp3";
import Abelha from "../../../../sounds/phraseSound/A_Abelha.mp3";
import Árvore from "../../../../sounds/phraseSound/A_Arvore.mp3";

import Baleia from "../../../../sounds/phraseSound/B_Baleia.mp3";
import Besouro from "../../../../sounds/phraseSound/B_Besouro.mp3";
import Bermuda from "../../../../sounds/phraseSound/B_Bermuda.mp3";

import Cachorro from "../../../../sounds/phraseSound/C_Cachorro.mp3";
import Cavalo from "../../../../sounds/phraseSound/C_Cavalo.mp3";
import Cenoura from "../../../../sounds/phraseSound/C_Cenoura.mp3";

import Dado from "../../../../sounds/phraseSound/D_Dado.mp3";
import Dente from "../../../../sounds/phraseSound/D_Dente.mp3";
import Dedo from "../../../../sounds/phraseSound/D_Dedo.mp3";

import Elefante from "../../../../sounds/phraseSound/E_Elefante.mp3";
import Estojo from "../../../../sounds/phraseSound/E_Estojo.mp3";
import Estrela from "../../../../sounds/phraseSound/E_Estrela.mp3";

import Fada from "../../../../sounds/phraseSound/F_Fada.mp3";
import Farol from "../../../../sounds/phraseSound/F_Farol.mp3";
import Ferro from "../../../../sounds/phraseSound/F_Ferro.mp3";

import Gamba from "../../../../sounds/phraseSound/G_Gamba.mp3";
import Gato from "../../../../sounds/phraseSound/G_Gato.mp3";
import Gelo from "../../../../sounds/phraseSound/G_Gelo.mp3";

import Hamburguer from "../../../../sounds/phraseSound/H_Hamburguer.mp3";
import Harpa from "../../../../sounds/phraseSound/H_Harpa.mp3";
import Helicoptero from "../../../../sounds/phraseSound/H_Helicoptero.mp3";

import Ilha from "../../../../sounds/phraseSound/I_Ilha.mp3";
import Indio from "../../../../sounds/phraseSound/I_Indio.mp3";
import Iogurte from "../../../../sounds/phraseSound/I_Iogurte.mp3";

import Jacare from "../../../../sounds/phraseSound/J_Jacare.mp3";
import Janela from "../../../../sounds/phraseSound/J_Janela.mp3";
import Jornal from "../../../../sounds/phraseSound/J_Jornal.mp3";

import Kuara from "../../../../sounds/phraseSound/K_Kuara.mp3";
import Kurumi from "../../../../sounds/phraseSound/K_Kurumi.mp3";
import Kururu from "../../../../sounds/phraseSound/K_Kururu.mp3";

import Lampada from "../../../../sounds/phraseSound/L_Lampada.mp3";
import Lapis from "../../../../sounds/phraseSound/L_Lapis.mp3";
import Leao from "../../../../sounds/phraseSound/L_Leao.mp3";

import Macaco from "../../../../sounds/phraseSound/M_Macaco.mp3";
import Mapa from "../../../../sounds/phraseSound/M_Mapa.mp3";
import Meia from "../../../../sounds/phraseSound/M_Meia.mp3";

import Navio from "../../../../sounds/phraseSound/N_Navio.mp3";
import Nariz from "../../../../sounds/phraseSound/N_Nariz.mp3";
import Neve from "../../../../sounds/phraseSound/N_Neve.mp3";

import Ovo from "../../../../sounds/phraseSound/O_Ovo.mp3";
import Oculos from "../../../../sounds/phraseSound/O_Oculos.mp3";
import Onibus from "../../../../sounds/phraseSound/O_Onibus.mp3";

import Palhaco from "../../../../sounds/phraseSound/P_Palhaco.mp3";
import Pato from "../../../../sounds/phraseSound/P_Pato.mp3";
import Pipoca from "../../../../sounds/phraseSound/P_Pipoca.mp3";

import Quadrado from "../../../../sounds/phraseSound/Q_Quadrado.mp3";
import Quadro from "../../../../sounds/phraseSound/Q_Quadro.mp3";
import Quatro from "../../../../sounds/phraseSound/Q_Quatro.mp3";

import Raposa from "../../../../sounds/phraseSound/R_Raposa.mp3";
import Rato from "../../../../sounds/phraseSound/R_Rato.mp3";
import Rei from "../../../../sounds/phraseSound/R_Rei.mp3";

import Sol from "../../../../sounds/phraseSound/S_Sol.mp3";
import Sapo from "../../../../sounds/phraseSound/S_Sapo.mp3";
import Sapato from "../../../../sounds/phraseSound/S_Sapato.mp3";

import Tatu from "../../../../sounds/phraseSound/T_Tatu.mp3";
import Tartaruga from "../../../../sounds/phraseSound/T_Tartaruga.mp3";
import Tesoura from "../../../../sounds/phraseSound/T_Tesoura.mp3";

import Uva from "../../../../sounds/phraseSound/U_Uva.mp3";
import Urso from "../../../../sounds/phraseSound/U_Urso.mp3";
import Urubu from "../../../../sounds/phraseSound/U_Urubu.mp3";

import Vaca from "../../../../sounds/phraseSound/V_Vaca.mp3";
import Vaso from "../../../../sounds/phraseSound/V_Vaso.mp3";
import Vassoura from "../../../../sounds/phraseSound/V_Vassoura.mp3";

import Wasai from "../../../../sounds/phraseSound/W_Wasai.mp3";
import Warima from "../../../../sounds/phraseSound/W_Warima.mp3";
import Wirapaga from "../../../../sounds/phraseSound/W_Wirapaga.mp3";

import Xaina from "../../../../sounds/phraseSound/X_Xaina.mp3";
import Xaxim from "../../../../sounds/phraseSound/X_Xaxim.mp3";
import Xui from "../../../../sounds/phraseSound/X_Xui.mp3";

import Ybyra from "../../../../sounds/phraseSound/Y_Ybyra.mp3";
import Ybytu from "../../../../sounds/phraseSound/Y_Ybytu.mp3";
import Ygara from "../../../../sounds/phraseSound/Y_Ygara.mp3";

import Zebra from "../../../../sounds/phraseSound/Z_Zebra.mp3";
import Ziper from "../../../../sounds/phraseSound/Z_Ziper.mp3";
import Zumbi from "../../../../sounds/phraseSound/Z_Zumbi.mp3";


import A_Abelha from "../../../../img/WordPhotos/Ilustrations/A_Abelha.svg";
import A_Arvore from "../../../../img/WordPhotos/Ilustrations/A_Arvore.svg";
import A_Aranha from "../../../../img/WordPhotos/Ilustrations/A_Aranha.svg";

import B_Baleia from "../../../../img/WordPhotos/Ilustrations/B_Baleia.svg";
import B_Bermuda from "../../../../img/WordPhotos/Ilustrations/B_Bermuda.svg";
import B_Besouro from "../../../../img/WordPhotos/Ilustrations/B_Besouro.svg";

import C_Cachorro from "../../../../img/WordPhotos/Ilustrations/C_Cachorro.svg";
import C_Cavalo from "../../../../img/WordPhotos/Ilustrations/C_Cavalo.svg";
import C_Cenoura from "../../../../img/WordPhotos/Ilustrations/C_Cenoura.svg";

import D_Dado from "../../../../img/WordPhotos/Ilustrations/D_Dado.svg";
import D_Dedo from "../../../../img/WordPhotos/Ilustrations/D_Dedo.svg";
import D_Dente from "../../../../img/WordPhotos/Ilustrations/D_Dente.svg";

import E_Elefante from "../../../../img/WordPhotos/Ilustrations/E_Elefante.svg";
import E_Estojo from "../../../../img/WordPhotos/Ilustrations/E_Estojo.svg";
import E_Estrela from "../../../../img/WordPhotos/Ilustrations/E_Estrela.svg";

import F_Fada from "../../../../img/WordPhotos/Ilustrations/F_Fada.svg";
import F_Farol from "../../../../img/WordPhotos/Ilustrations/F_Farol.svg";
import F_Ferro from "../../../../img/WordPhotos/Ilustrations/F_Ferro.svg";

import G_Gamba from "../../../../img/WordPhotos/Ilustrations/G_Gamba.svg";
import G_Gato from "../../../../img/WordPhotos/Ilustrations/G_Gato.svg";
import G_Gelo from "../../../../img/WordPhotos/Ilustrations/G_Gelo.svg";

import H_Hamburguer from "../../../../img/WordPhotos/Ilustrations/H_Hamburguer.svg";
import H_Harpa from "../../../../img/WordPhotos/Ilustrations/H_Harpa.svg";
import H_Helicoptero from "../../../../img/WordPhotos/Ilustrations/H_Helicoptero.svg";

import I_Indio from "../../../../img/WordPhotos/Ilustrations/I_Indio.svg";
import I_Ilha from "../../../../img/WordPhotos/Ilustrations/I_Ilha.svg";
import I_Iogurte from "../../../../img/WordPhotos/Ilustrations/I_Iogurte.svg";

import J_Jacare from "../../../../img/WordPhotos/Ilustrations/J_Jacare.svg";
import J_Janela from "../../../../img/WordPhotos/Ilustrations/J_Janela.svg";
import J_Jornal from "../../../../img/WordPhotos/Ilustrations/J_Jornal.svg";

import K_Kuara from "../../../../img/WordPhotos/Ilustrations/K_Kuara.svg";
import K_Kurumi from "../../../../img/WordPhotos/Ilustrations/K_Kurumi.svg";
import K_Kururu from "../../../../img/WordPhotos/Ilustrations/K_Kururu.svg";
  
import L_Lampada from "../../../../img/WordPhotos/Ilustrations/L_Lampada.svg";
import L_Lapis from "../../../../img/WordPhotos/Ilustrations/L_Lapis.svg";
import L_Leao from "../../../../img/WordPhotos/Ilustrations/L_Leao.svg";

import M_Macaco from "../../../../img/WordPhotos/Ilustrations/M_Macaco.svg";
import M_Mapa from "../../../../img/WordPhotos/Ilustrations/M_Mapa.svg";
import M_Meia from "../../../../img/WordPhotos/Ilustrations/M_Meia.svg";

import N_Navio from "../../../../img/WordPhotos/Ilustrations/N_Navio.svg";
import N_Nariz from "../../../../img/WordPhotos/Ilustrations/N_Nariz.svg";
import N_Neve from "../../../../img/WordPhotos/Ilustrations/N_Neve.svg";

import O_Ovo from "../../../../img/WordPhotos/Ilustrations/O_Ovo.svg";
import O_Oculos from "../../../../img/WordPhotos/Ilustrations/O_Oculos.svg";
import O_Onibus from "../../../../img/WordPhotos/Ilustrations/O_Onibus.svg";
   
import P_Palhaco from "../../../../img/WordPhotos/Ilustrations/P_Palhaco.svg";
import P_Pato from "../../../../img/WordPhotos/Ilustrations/P_Pato.svg";
import P_Pipoca from "../../../../img/WordPhotos/Ilustrations/P_Pipoca.svg";

import Q_Quadrado from "../../../../img/WordPhotos/Ilustrations/Q_Quadrado.svg";
import Q_Quadro from "../../../../img/WordPhotos/Ilustrations/Q_Quadro.svg";
import Q_Quatro from "../../../../img/WordPhotos/Ilustrations/Q_Quatro.svg";

import R_Raposa from "../../../../img/WordPhotos/Ilustrations/R_Raposa.svg";
import R_Rato from "../../../../img/WordPhotos/Ilustrations/R_Rato.svg";
import R_Rei from "../../../../img/WordPhotos/Ilustrations/R_Rei.svg";
  
import S_Sol from "../../../../img/WordPhotos/Ilustrations/S_Sol.svg";
import S_Sapo from "../../../../img/WordPhotos/Ilustrations/S_Sapo.svg";
import S_Sapato from "../../../../img/WordPhotos/Ilustrations/S_Sapato.svg";
   
import T_Tatu from "../../../../img/WordPhotos/Ilustrations/T_Tatu.svg";
import T_Tartaruga from "../../../../img/WordPhotos/Ilustrations/T_Tartaruga.svg";
import T_Tesoura from "../../../../img/WordPhotos/Ilustrations/T_Tesoura.svg";

import U_Uva from "../../../../img/WordPhotos/Ilustrations/U_Uva.svg";
import U_Urso from "../../../../img/WordPhotos/Ilustrations/U_Urso.svg";
import U_Urubu from "../../../../img/WordPhotos/Ilustrations/U_Urubu.svg";
  
import V_Vaca from "../../../../img/WordPhotos/Ilustrations/V_Vaca.svg";
import V_Vaso from "../../../../img/WordPhotos/Ilustrations/V_Vaso.svg";
import V_Vassoura from "../../../../img/WordPhotos/Ilustrations/V_Vassoura.svg";
  
import W_Wasai from "../../../../img/WordPhotos/Ilustrations/W_Wasai.svg";
import W_Warima from "../../../../img/WordPhotos/Ilustrations/W_Warima.svg";
import W_Wirapaga from "../../../../img/WordPhotos/Ilustrations/W_Wirapaga.svg";

import X_Xaina from "../../../../img/WordPhotos/Ilustrations/X_Xaina.svg";
import X_Xaxim from "../../../../img/WordPhotos/Ilustrations/X_Xaxim.svg";
import X_Xui from "../../../../img/WordPhotos/Ilustrations/X_Xui.svg";

import Y_Ybyra from "../../../../img/WordPhotos/Ilustrations/Y_Ybyra.svg";
import Y_Ybytu from "../../../../img/WordPhotos/Ilustrations/Y_Ybytu.svg";
import Y_Ygara from "../../../../img/WordPhotos/Ilustrations/Y_Ygara.svg";

import Z_Zebra from "../../../../img/WordPhotos/Ilustrations/Z_Zebra.svg";
import Z_Ziper from "../../../../img/WordPhotos/Ilustrations/Z_Ziper.svg";
import Z_Zumbi from "../../../../img/WordPhotos/Ilustrations/Z_Zumbi.svg";


export const WordList = [
  { letter: "A", word: "Aranha", indexWord: 1, sound: Aranha, image: A_Aranha },
  {
    letter: "A",
    word: "Abelha",
    indexWord: 2,
    sound: Abelha,
    image: A_Abelha,
  },
  { letter: "A", word: "Árvore", indexWord: 3, sound: Árvore, image: A_Arvore },
  { letter: "B", word: "Baleia", indexWord: 1, sound: Baleia, image: B_Baleia },
  {
    letter: "B",
    word: "Besouro",
    indexWord: 2,
    sound: Besouro,
    image: B_Besouro,
  },
  {
    letter: "B",
    word: "Bermuda",
    indexWord: 3,
    sound: Bermuda,
    image: B_Bermuda,
  },
  {
    letter: "C",
    word: "Cachorro",
    indexWord: 1,
    sound: Cachorro,
    image: C_Cachorro,
  },
  { letter: "C", word: "Cavalo", indexWord: 2, sound: Cavalo, image: C_Cavalo },
  {
    letter: "C",
    word: "Cenoura",
    indexWord: 3,
    sound: Cenoura,
    image: C_Cenoura,
  },
  { letter: "D", word: "Dado", indexWord: 1, image: D_Dado, sound: Dado },
  { letter: "D", word: "Dente", indexWord: 2, image: D_Dente, sound: Dente },
  { letter: "D", word: "Dedo", indexWord: 3, image: D_Dedo, sound: Dedo },
  {
    letter: "E",
    word: "Elefante",
    indexWord: 1,
    sound: Elefante,
    image: E_Elefante,
  },
  {
    letter: "E",
    word: "Estrela",
    indexWord: 2,
    image: E_Estrela,
    sound: Estrela,
  },
  { letter: "E", word: "Estojo", indexWord: 3, image: E_Estojo, sound: Estojo },
  { letter: "F", word: "Fada", indexWord: 1, image: F_Fada, sound: Fada },
  { letter: "F", word: "Farol", indexWord: 2, image: F_Farol, sound: Farol },
  { letter: "F", word: "Ferro", indexWord: 3, image: F_Ferro, sound: Ferro },
  { letter: "G", word: "Gato", indexWord: 1, image: G_Gato, sound: Gato },
  { letter: "G", word: "Gambá", indexWord: 2, image: G_Gamba, sound: Gamba },
  { letter: "G", word: "Gelo", indexWord: 3, image: G_Gelo, sound: Gelo },
  { letter: "H", word: "Harpa", indexWord: 1, image: H_Harpa, sound: Harpa },
  { letter: "H", word: "Hambúrguer", indexWord: 2, image: H_Hamburguer, sound: Hamburguer },
  { letter: "H", word: "Helicóptero", indexWord: 3, image: H_Helicoptero , sound: Helicoptero},
  { letter: "I", word: "Índio", indexWord: 1, image: I_Indio , sound: Indio},
  { letter: "I", word: "Iogurte", indexWord: 2, image: I_Iogurte , sound: Iogurte},
  { letter: "I", word: "Ilha", indexWord: 3, image: I_Ilha,  sound: Ilha},
  { letter: "J", word: "Janela", indexWord: 1, image: J_Janela,  sound: Janela },
  { letter: "J", word: "Jacaré", indexWord: 2, image: J_Jacare ,  sound: Jacare},
  { letter: "J", word: "Jornal", indexWord: 3, image: J_Jornal ,  sound: Jornal},
  { letter: "K", word: "Kuara", indexWord: 1, image: K_Kuara ,  sound: Kuara },
  { letter: "K", word: "Kurumi", indexWord: 2, image: K_Kurumi ,  sound: Kurumi },
  { letter: "K", word: "Kururu", indexWord: 3, image: K_Kururu ,  sound: Kururu },
  { letter: "L", word: "Lâmpada", indexWord: 1, image: L_Lampada ,  sound: Lampada },
  { letter: "L", word: "Lápis", indexWord: 2, image: L_Lapis,  sound: Lapis },
  { letter: "L", word: "Leão", indexWord: 3, image: L_Leao ,  sound: Leao },
  { letter: "M", word: "Macaco", indexWord: 1, image: M_Macaco ,  sound: Macaco },
  { letter: "M", word: "Mapa", indexWord: 2, image: M_Mapa ,  sound: Mapa },
  { letter: "M", word: "Meia", indexWord: 3, image: M_Meia ,  sound: Meia },
  { letter: "N", word: "Navio", indexWord: 1, image: N_Navio ,  sound: Navio },
  { letter: "N", word: "Nariz", indexWord: 2, image: N_Nariz ,  sound: Nariz },
  { letter: "N", word: "Neve", indexWord: 3, image: N_Neve ,  sound: Neve },
  { letter: "O", word: "Ovo", indexWord: 1, image: O_Ovo ,  sound: Ovo },
  { letter: "O", word: "Óculos", indexWord: 2, image: O_Oculos ,  sound: Oculos },
  { letter: "O", word: "Ônibus", indexWord: 3, image:O_Onibus ,  sound: Onibus },
  { letter: "P", word: "Palhaço", indexWord: 1, image: P_Palhaco ,  sound: Palhaco },
  { letter: "P", word: "Pato", indexWord: 2, image: P_Pato ,  sound: Pato },
  { letter: "P", word: "Pipoca", indexWord: 3, image: P_Pipoca ,  sound: Pipoca },
  { letter: "Q", word: "Quadrado", indexWord: 1, image: Q_Quadrado ,  sound: Quadrado },
  { letter: "Q", word: "Quadro", indexWord: 2, image: Q_Quadro ,  sound: Quadro },
  { letter: "Q", word: "Quatro", indexWord: 3, image: Q_Quatro ,  sound: Quatro },
  { letter: "R", word: "Raposa", indexWord: 1, image: R_Raposa ,  sound: Raposa },
  { letter: "R", word: "Rato", indexWord: 2, image: R_Rato ,  sound: Rato },
  { letter: "R", word: "Rei", indexWord: 3, image:R_Rei ,  sound: Rei },
  { letter: "S", word: "Sol", indexWord: 1, image: S_Sol ,  sound: Sol },
  { letter: "S", word: "Sapo", indexWord: 2, image: S_Sapo,  sound: Sapo },
  { letter: "S", word: "Sapato", indexWord: 3, image: S_Sapato ,  sound: Sapato },
  { letter: "T", word: "Tatu", indexWord: 1, image: T_Tatu ,  sound: Tatu },
  { letter: "T", word: "Tartaruga", indexWord: 2, image: T_Tartaruga ,  sound: Tartaruga },
  { letter: "T", word: "Tesoura", indexWord: 3, image: T_Tesoura ,  sound: Tesoura },
  { letter: "U", word: "Uva", indexWord: 1, image: U_Uva,  sound: Uva },
  { letter: "U", word: "Urso", indexWord: 2, image: U_Urso ,  sound: Urso },
  { letter: "U", word: "Urubu", indexWord: 3, image: U_Urubu ,  sound: Urubu },
  { letter: "V", word: "Vaca", indexWord: 1, image: V_Vaca ,  sound: Vaca },
  { letter: "V", word: "Vaso", indexWord: 2, image: V_Vaso ,  sound: Vaso },
  { letter: "V", word: "Vassoura", indexWord: 3, image: V_Vassoura ,  sound: Vassoura },
  { letter: "W", word: "Wasai", indexWord: 1, image: W_Wasai,  sound: Wasai },
  { letter: "W", word: "Warima", indexWord: 2, image: W_Warima ,  sound: Warima },
  { letter: "W", word: "Wirapaga", indexWord: 3, image: W_Wirapaga ,  sound: Wirapaga },
  { letter: "X", word: "Xaina", indexWord: 1, image: X_Xaina,  sound: Xaina },
  { letter: "X", word: "Xaxim", indexWord: 2, image: X_Xaxim,  sound: Xaxim },
  { letter: "X", word: "Xui", indexWord: 3, image: X_Xui,  sound: Xui },
  { letter: "Y", word: "Ybirá", indexWord: 1, image: Y_Ybyra,  sound: Ybyra },
  { letter: "Y", word: "Ybytu", indexWord: 2, image: Y_Ybytu ,  sound: Ybytu },
  { letter: "Y", word: "Ygara", indexWord: 3, image: Y_Ygara ,  sound: Ygara },
  { letter: "Z", word: "Zebra", indexWord: 1, image: Z_Zebra ,  sound: Zebra },
  { letter: "Z", word: "Zíper", indexWord: 2, image: Z_Ziper ,  sound: Ziper },
  { letter: "Z", word: "Zumbi", indexWord: 3, image: Z_Zumbi ,  sound: Zumbi },
];

export {
  A_Forms,
  B_Forms,
  C_Forms,
  D_Forms,
  E_Forms,
  F_Forms,
  G_Forms,
  H_Forms,
  I_Forms,
  J_Forms,
  K_Forms,
  L_Forms,
  M_Forms,
  N_Forms,
  O_Forms,
  P_Forms,
  Q_Forms,
  R_Forms,
  S_Forms,
  T_Forms,
  U_Forms,
  V_Forms,
  W_Forms,
  X_Forms,
  Y_Forms,
  Z_Forms,
};
