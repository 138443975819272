import React from "react";

// import { Container } from './styles';
import Header from "./Components/Header";
import Body from "./Components/Body";
import LetterOutlineProvider from "../../context/LetterOutline";

const LetterOutline: React.FC = () => {
  return (
    <>
      <LetterOutlineProvider>
        <Header />
        <Body />
      </LetterOutlineProvider>
    </>
  );
};

export default LetterOutline;
