import React from "react";

import {
  Container,
  MyCard,
  TextContent,
  Title,
  Subtitle,
  MyButton,
} from "./styles";
import Grid from "@material-ui/core/Grid";
import boneco1Svg from "../../../../img/boneco1.svg";
import boneco2Svg from "../../../../img/boneco2.svg";
import boneco3Svg from "../../../../img/boneco3.svg";
import { useUserPageContext } from "../../../../context/PagesIndicator";

const Cards: React.FC = () => {
  const { setActivePage } = useUserPageContext();
  return (
    <>
      <Container>
        <Grid container spacing={3} justify="center">
          <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
            <MyCard>
              <img src={boneco1Svg} alt="Avatar" />
              <TextContent>
                <Title>Vamos aprender o nome das letras?</Title>
                <Subtitle>
                  Eu sou a Lulu e hoje vou ajudar você a conhecer o nome das
                  letras
                </Subtitle>
              </TextContent>
              <MyButton onClick={() => setActivePage("LetterSound")}>
                Vamos lá
              </MyButton>
            </MyCard>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
            <MyCard>
              <img src={boneco2Svg} alt="Avatar" />
              <TextContent>
                <Title>Vamos aprender os tipos das letras?</Title>
                <Subtitle>
                  Eu sou o Tony e hoje vou mostrar a forma das letras
                </Subtitle>
              </TextContent>
              <MyButton onClick={() => setActivePage("LetterWriting")}>
                Vamos lá
              </MyButton>
            </MyCard>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
            <MyCard>
              <img src={boneco3Svg} alt="Avatar" />
              <TextContent>
                <Title>Vamos aprender a grafia das letras?</Title>
                <Subtitle>
                  Eu sou a Popy e vou mostrar como escrever as letras
                </Subtitle>
              </TextContent>
              <MyButton onClick={() => setActivePage("LetterOutline")}>
                Vamos lá
              </MyButton>
            </MyCard>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Cards;
