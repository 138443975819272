import React, { useState, useEffect } from "react";

import {
  MyImage,
  WordContent,
  SpeakerSvg,
  Text,
  NumbersContent,
  Number,
} from "./styles";
import { useLetterWritingContext } from "../../../../context/LetterWriting";

import { WordList } from "./Exports";

import { Howl, Howler } from "howler";

const Body: React.FC = () => {
  const { letterAux } = useLetterWritingContext();
  const [number1Click, setNumber1Click] = useState(true);
  const [number2Click, setNumber2Click] = useState(false);
  const [number3Click, setNumber3Click] = useState(false);
  const [auxNumber, setAuxNumber] = useState(1);
  const [playing, setPlaying] = useState(false);

  useEffect(() => {
    setAuxNumber(1);
    setNumber1Click(true);
    setNumber2Click(false);
    setNumber3Click(false);
  }, [letterAux]);

  const SoundPlay = (src: any) => {
    if (playing) {
      console.log("Double Click Protection");
    } else {
      const sound = new Howl({
        src,
        html5: true,
        onplay: function () {
          setPlaying(true);
        },
        onend: function () {
          setPlaying(false);
        },
      });
      sound.play();
    }
  };
  Howler.volume(1.0);

  return (
    <>
      {WordList.map((information, index) => {
        return letterAux === information.letter &&
          auxNumber === information.indexWord ? (
          <div
            key={index}
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <MyImage src={information.image} alt="Ilustração da Palavra" />
            <WordContent>
              <SpeakerSvg
                onClick={() => {
                  SoundPlay(WordList[index].sound);
                }}
              >
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M22.0254 31.9999C21.6641 31.9999 21.3197 31.7719 21.1658 31.3897C20.9585 30.8749 21.175 30.2752 21.6495 30.0504C26.7981 27.6096 30.1249 22.0945 30.1249 15.9997C30.1246 9.9053 26.7981 4.39028 21.65 1.94961C21.1755 1.72468 20.959 1.12502 21.1663 0.610269C21.3736 0.0954455 21.9263 -0.139724 22.4008 0.0854772C28.2317 2.84987 31.9996 9.09659 31.9998 15.9996C31.9998 22.9031 28.2318 29.15 22.4003 31.9145C22.2781 31.9725 22.1507 31.9999 22.0254 31.9999Z"
                    fill="#ADD259"
                  />
                  <path
                    d="M22.026 24.325C21.7686 24.325 21.5123 24.2107 21.3271 23.9863C20.9818 23.5677 21.0145 22.9247 21.4003 22.55C23.1161 20.8835 24.1001 18.496 24.1001 15.9997C24.1001 13.5034 23.1161 11.116 21.4003 9.4495C21.0145 9.07484 20.9818 8.43178 21.3271 8.01319C21.6725 7.59472 22.2652 7.55912 22.651 7.93378C24.7635 9.98561 25.9751 12.9255 25.9751 15.9996C25.9751 19.0738 24.7635 22.0137 22.651 24.0655C22.4721 24.2396 22.2486 24.325 22.026 24.325Z"
                    fill="#ADD259"
                  />
                  <path
                    d="M16.4237 2.56313C16.1086 2.38981 15.7304 2.42351 15.4456 2.6502L8.16184 8.44542H0.937496C0.419811 8.44542 0 8.90084 0 9.46259V22.5367C0 23.0984 0.419811 23.5539 0.937496 23.5539H8.16184L15.4455 29.3492C15.6094 29.4797 15.8041 29.5461 16.0001 29.5461C16.1446 29.5461 16.2899 29.5099 16.4237 29.4363C16.7388 29.2631 16.9374 28.9122 16.9374 28.529V3.47045C16.9374 3.08718 16.7388 2.73639 16.4237 2.56313Z"
                    fill="#ADD259"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear"
                      x1="26.5435"
                      y1="0"
                      x2="26.5435"
                      y2="31.9999"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#ADD259" />
                      <stop offset="1" stopColor="#9CBE57" />
                    </linearGradient>
                    <linearGradient
                      id="paint1_linear"
                      x1="23.5316"
                      y1="7.6745"
                      x2="23.5316"
                      y2="24.325"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#ADD259" />
                      <stop offset="1" stopColor="#9CBE57" />
                    </linearGradient>
                    <linearGradient
                      id="paint2_linear"
                      x1="8.46871"
                      y1="2.45325"
                      x2="8.46871"
                      y2="29.5461"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#ADD259" />
                      <stop offset="1" stopColor="#9CBE57" />
                    </linearGradient>
                  </defs>
                </svg>
              </SpeakerSvg>
              <Text style={playing ? { color: "var(--darkGreen)" } : {}}>
                {information.word}
              </Text>
            </WordContent>
          </div>
        ) : (
          ""
        );
      })}
      <NumbersContent>
        <Number
          style={{ marginRight: "20px" }}
          onClick={() => {
            setAuxNumber(1);
            setNumber1Click(true);
            setNumber2Click(false);
            setNumber3Click(false);
          }}
          className={number1Click ? "activeNumber" : ""}
        >
          1
        </Number>
        <Number
          style={{ marginRight: "20px" }}
          onClick={() => {
            setAuxNumber(2);
            setNumber1Click(false);
            setNumber2Click(true);
            setNumber3Click(false);
          }}
          className={number2Click ? "activeNumber" : ""}
        >
          2
        </Number>
        <Number
          onClick={() => {
            setAuxNumber(3);
            setNumber1Click(false);
            setNumber2Click(false);
            setNumber3Click(true);
          }}
          className={number3Click ? "activeNumber" : ""}
        >
          3
        </Number>
      </NumbersContent>
    </>
  );
};

export default Body;
