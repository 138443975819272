import styled from "styled-components";

export const Container = styled.div`
  height: 70vh;
  width: 100%;
  background: white;
`;
export const LetterOutlineContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const ReloadIcon = styled.div`
  > svg {
    width: 35px;
    height: 35px;
    top: 10px;
    left: 10px;
    position: absolute;
    color: var(--darkGreen);
    fill: var(--darkGreen);
    :hover {
      transform: scale(1.2);
      transition: transform ease-out 0.5s;
    }
  }

  cursor: pointer;
`;
export const LetterBox = styled.div`
  width: 350px;
  height: 350px;
  border-radius: 16px;
  background-color: transparent;
  border: 1px solid var(--primaryPurple);
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  @media (max-width: 500px) {
    width: 250px;
    height: 250px;
  }
`;
export const MySvg = styled.div`
  > svg {
    overflow: visible;
    stroke: var(--primaryPurple);
    stroke-width: 3;

    stroke-linejoin: round;
    stroke-linecap: round;
  }
`;
