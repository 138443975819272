import styled from "styled-components";

export const Container = styled.div`
  @media (min-width: 1440px) {
    max-width: 1500px;
  }
  margin: 5% auto 3%;
  @media (max-width: 768px) {
    margin: 15% auto 3%;
  }
`;

export const MyCard = styled.div`
  background-color: var(--CardColor);
  border-radius: 12px;
  padding: 15px;
  width: 350px;
  @media (max-width: 500px) {
    width: 265px;
  }
  margin: auto;
  text-align: center;
  > img {
    width: 175px;
    height: 255px;
    @media (max-width: 500px) {
      width: 160px;
      height: 240px;
    }
    margin-top: 10%;
  }
`;
export const TextContent = styled.div`
  text-align: center;
  margin-top: 10%;
`;
export const Title = styled.div`
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: var(--fontBlack);
`;
export const Subtitle = styled.div`
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height:150%;
  margin-top: 7%;
  color: var(--fontBlack);
`;
export const MyButton = styled.div`
  padding: 15px 40px;
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  background-color: var(--darkGreen);
  color: white;
  border-radius: 6px;
  width: max-content;
  margin: 8% auto 0;
  cursor: pointer;
`;
