import React, { useState } from "react";

import { useLetterWritingContext } from "../../../../context/LetterWriting";
import {
  ContainerDesktop,
  LetterPhotoContent,
  WordSpeelingContent,
  ImageLetterForm,
  ContainerCellphone,
  ButtonContent,
  MyButton,
} from "./styles";

import WordSpelling from "./WordSpelling";

import {
  A_Forms,
  B_Forms,
  C_Forms,
  D_Forms,
  E_Forms,
  F_Forms,
  G_Forms,
  H_Forms,
  I_Forms,
  J_Forms,
  K_Forms,
  L_Forms,
  M_Forms,
  N_Forms,
  O_Forms,
  P_Forms,
  Q_Forms,
  R_Forms,
  S_Forms,
  T_Forms,
  U_Forms,
  V_Forms,
  W_Forms,
  X_Forms,
  Y_Forms,
  Z_Forms,
} from "./Exports";

const Body: React.FC = () => {
  const { letterAux } = useLetterWritingContext();
  const [buttonLetras, setButtonLetras] = useState(true);
  const [buttonPalavras, setButtonPalavras] = useState(false);
  const photoList = [
    { label: "A", image: A_Forms },
    { label: "B", image: B_Forms },
    { label: "C", image: C_Forms },
    { label: "D", image: D_Forms },
    { label: "E", image: E_Forms },
    { label: "F", image: F_Forms },
    { label: "G", image: G_Forms },
    { label: "H", image: H_Forms },
    { label: "I", image: I_Forms },
    { label: "J", image: J_Forms },
    { label: "K", image: K_Forms },
    { label: "L", image: L_Forms },
    { label: "M", image: M_Forms },
    { label: "N", image: N_Forms },
    { label: "O", image: O_Forms },
    { label: "P", image: P_Forms },
    { label: "Q", image: Q_Forms },
    { label: "R", image: R_Forms },
    { label: "S", image: S_Forms },
    { label: "T", image: T_Forms },
    { label: "U", image: U_Forms },
    { label: "V", image: V_Forms },
    { label: "W", image: W_Forms },
    { label: "X", image: X_Forms },
    { label: "Y", image: Y_Forms },
    { label: "Z", image: Z_Forms },
  ];

  return (
    <>
      <ContainerDesktop>
        <LetterPhotoContent>
          {photoList.map((information) => {
            return letterAux === information.label ? (
              <ImageLetterForm
                src={information.image}
                alt="Ilustração das formas da letra"
                key={information.label}
              />
            ) : (
              ""
            );
          })}
        </LetterPhotoContent>
        <WordSpeelingContent>
          <WordSpelling />
        </WordSpeelingContent>
      </ContainerDesktop>

      <ContainerCellphone>
        <ButtonContent>
          <MyButton
            onClick={() => {
              setButtonLetras(true);
              setButtonPalavras(false);
            }}
            style={
              buttonLetras ? { backgroundColor: "var(--primaryPurple)" } : {}
            }
          >
            Letra
          </MyButton>
          <MyButton
            onClick={() => {
              setButtonLetras(false);
              setButtonPalavras(true);
            }}
            style={
              buttonPalavras ? { backgroundColor: "var(--primaryPurple)" } : {}
            }
          >
            Palavras
          </MyButton>
        </ButtonContent>
        {buttonLetras ? (
          <>
            <LetterPhotoContent>
              {photoList.map((information) => {
                return letterAux === information.label ? (
                  <ImageLetterForm
                    src={information.image}
                    alt="Ilustração das formas da letra"
                    key={information.label}
                  />
                ) : (
                  ""
                );
              })}
            </LetterPhotoContent>
          </>
        ) : (
          ""
        )}
        {buttonPalavras ? (
          <>
            <WordSpeelingContent>
              <WordSpelling />
            </WordSpeelingContent>
          </>
        ) : (
          ""
        )}
      </ContainerCellphone>
    </>
  );
};

export default Body;
