import React, { useState, useEffect } from "react";

import {
  Container,
  TopContent,
  ArrowBack,
  NavBar,
  Letter,
  ArrowsContent,
  LeftArrow,
  RightArrow,
} from "./styles";
import Logo from "../../../../img/logoAplique.png";
import { useUserPageContext } from "../../../../context/PagesIndicator";
import { useLetterWritingContext } from "../../../../context/LetterWriting";
const Header: React.FC = () => {
  var letterList = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
  ];
  const { setActivePage } = useUserPageContext();
  const { letterAux, setLetterAux } = useLetterWritingContext();
  const [clickRight, setClickRight] = useState(0);
  useEffect(() => {
    if (clickRight === 20 || clickRight === -20) {
      setClickRight(0);
    }
  }, [clickRight]);
  return (
    <>
      <Container>
        <TopContent>
          <ArrowBack onClick={() => setActivePage("MenuIntroduction")}>
            <svg viewBox="0 0 13 8" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M0.646446 3.64645C0.451184 3.84171 0.451184 4.15829 0.646446 4.35355L3.82843 7.53553C4.02369 7.7308 4.34027 7.7308 4.53553 7.53553C4.7308 7.34027 4.7308 7.02369 4.53553 6.82843L1.70711 4L4.53553 1.17157C4.7308 0.97631 4.7308 0.659728 4.53553 0.464465C4.34027 0.269203 4.02369 0.269203 3.82843 0.464465L0.646446 3.64645ZM13 3.5L1 3.5L1 4.5L13 4.5L13 3.5Z"
                fill="white"
              />
            </svg>
            <span>Voltar</span>
          </ArrowBack>
          <div style={{ width: "89%", textAlign: "center" }}>
            <img
              src={Logo}
              alt="Logo"
              style={{ cursor: "pointer" }}
              onClick={() => setActivePage("MenuIntroduction")}
            />
          </div>
        </TopContent>
        <ArrowsContent>
          <LeftArrow onClick={() => setClickRight(clickRight + 4)}>
            <svg
              width="13"
              height="24"
              viewBox="0 0 13 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.5 2L2 12.5L10.5 22"
                stroke="#fff"
                strokeWidth="4"
                strokeLinejoin="round"
              />
            </svg>
          </LeftArrow>
          <RightArrow
            onClick={() => {
              setClickRight(clickRight - 4);
            }}
          >
            <svg
              width="13"
              height="24"
              viewBox="0 0 13 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2 22L10.5 11.5L2 2"
                stroke="#fff"
                strokeWidth="4"
                strokeLinejoin="round"
              />
            </svg>
          </RightArrow>
        </ArrowsContent>
        <NavBar>
          {letterList.map((name, index) => (
            <Letter
              onClick={() => setLetterAux(name)}
              className={name === letterAux ? "active" : ""}
              key={index}
              style={
                index === 0
                  ? clickRight <= 20 && clickRight >= -20
                    ? { marginLeft: `${clickRight * 2}%` }
                    : { marginLeft: "0" }
                  : {}
              }
            >
              {name}
            </Letter>
          ))}
        </NavBar>
      </Container>
    </>
  );
};

export default Header;
