import styled from "styled-components";

export const Container = styled.div`
  margin: 5% auto 3%;
  @media (max-width: 768px) {
    margin: 20% auto 3%;
  }
  > div {
    @media (max-width: 450px) {
      padding: 0 50px;
    }
    @media (max-width: 400px) {
      padding: 0 35px;
    }
    @media (max-width: 350px) {
      padding: 0 12px;
    }
  }
  width: 100%;
`;
export const MyBox = styled.div`
  width: 255px;
  height: 255px;
  @media (max-width: 960px) {
    width: 200px;
    height: 200px;
  }
  @media (max-width: 768px) {
    width: 175px;
    height: 175px;
  }
  @media (max-width: 450px) {
    width: 140px;
    height: 140px;
  }

  background-color: white;
  border-radius: 16px;
  box-shadow: 0px 0px 32px rgba(105, 105, 105, 0.1);
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  > div {
    position: absolute;
    background-color: var(--primaryGreen);
    height: 100%;
    left: 0;
    border-radius: 16px;
  }

  > span {
    font-family: "Fira Sans";
    font-style: normal;
    font-weight: bold;
    font-size: 128px;
    z-index: 10000;
    @media (max-width: 960px) {
      font-size: 100px;
    }
    @media (max-width: 768px) {
      font-size: 80px;
    }
    @media (max-width: 450px) {
      font-size: 70px;
    }
    color: var(--fontBlack);
  }
`;
