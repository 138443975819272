import React from "react";

import Header from "./components/Header";
import Letters from "./components/Letters";

const LetterSound: React.FC = () => {
  return (
    <>
      <Header />
      <Letters />
    </>
  );
};

export default LetterSound;
