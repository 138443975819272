import styled from "styled-components";

export const ContainerDesktop = styled.div`
  height: 70vh;
  width: 100%;
  display: flex;
  background: white;
  @media (max-width: 768px) {
    flex-direction: column;
  }
  @media (min-width: 769px) {
    display: flex;
  }
  @media (max-width: 768px) {
    display: none;
  }
`;
export const ContainerCellphone = styled.div`
  height: 70vh;
  width: 100%;
  display: flex;
  background: white;
  flex-direction: column;
  @media (max-width: 768px) {
    display: flex;
  }
  @media (min-width: 769px) {
    display: none;
  }
`;
export const LetterPhotoContent = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    width: 100%;
  }
  background-color: white;
`;
export const ImageLetterForm = styled.img`
  height: 60vh;
  @media (max-width: 1110px) {
    height: 50vh;
  }
`;
export const WordSpeelingContent = styled.div`
  width: 50%;
  @media (max-width: 768px) {
    width: 100%;
  }
  background-color: white;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const MyImage = styled.img`
  @media (max-width: 500px) {
    width: 230px;
    height: 210px;
  }
  @media (min-width: 501px) {
    height: 37vh;
  }
  flex-shrink: 0;
`;
export const WordContent = styled.div`
  margin: 5% auto;
  @media (max-width: 500px) {
    margin: 15% auto 10% auto;
  }
  @media (min-width: 1440px) {
    width: 50%;
  }
  width: 80%;
  background: var(--primaryPurple);
  border-radius: 20px;
  padding: 15px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const SpeakerSvg = styled.div`
  cursor: pointer;
`;
export const Text = styled.div`
  margin-left: 10%;
  text-transform: uppercase;
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  @media (max-width: 500px) {
    font-size: 32px;
  }
  color: white;
  letter-spacing: 5px;
  transition: color ease-out 0.5s;
`;
export const NumbersContent = styled.div`
  display: flex;
  margin: 2% 0 5% 0;
`;
export const Number = styled.div`
  width: 50px;
  height: 50px;
  background-color: transparent;
  color: rgba(0, 0, 0, 0.9);
  display: flex;
  border: 1px solid var(--darkGreen);
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 8px;
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  &.activeNumber {
    background-color: var(--darkGreen);
  }
`;
export const ButtonContent = styled.div`
  margin-top: 6%;
  margin-bottom: 6%;
  display: flex;
  justify-content: space-around;
  @media (max-width: 768px) {
    justify-content: space-evenly;
  }
`;
export const MyButton = styled.div`
  color: white;
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  padding: 8px 30px;
  @media (max-width: 768px) {
    font-size: 24px;
    padding: 16px 40px;
  }
  background-color: #bdbcbc;
  border-radius: 8px;
`;
