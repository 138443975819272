import React from "react";

import { Container, FirstTitle } from "./styles";
import Logo from "../../../../img/logoAplique.png";

const Header: React.FC = () => {
  return (
    <Container>
      <img src={Logo} alt="Logo" />
      <FirstTitle>
        Com quem você quer <br />
        embarcar nessa viagem?
      </FirstTitle>
    </Container>
  );
};

export default Header;
