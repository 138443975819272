import React from "react";

import Header from "./components/Header";
import Body from "./components/Body";

import LetterWritingProvider from "../../context/LetterWriting";

const LetterWriting: React.FC = () => {
  return (
    <>
      <LetterWritingProvider>
        <Header />
        <Body />
      </LetterWritingProvider>
    </>
  );
};

export default LetterWriting;
