import "./styles.css";
export interface Props1 {
  nameClass?: string;
}
export const A_svg: React.FC<Props1> = ({ nameClass }) => {
  return (
    <div className="handwriting">
      <svg
        width="28.36mm"
        height="30.303mm"
        version="1.1"
        viewBox="0 0 28.36 30.303"
        xmlns="http://www.w3.org/2000/svg"
      >
        <clipPath id="clipPath865">
          <path
            d="m69.614 87.246 11.638-30.303h4.3201l12.402 30.303h-4.5682l-3.5347-9.1777h-12.671l-3.328 9.1777zm8.7436-12.444h10.273l-3.1626-8.3922q-1.4469-3.8241-2.1497-6.2838-0.57878 2.9145-1.633 5.7878z"
            strokeWidth=".26458"
          />
        </clipPath>
        <g transform="translate(-69.629 -56.96)">
          <path
            className={nameClass === "Restart" ? "" : "text"}
            d="m71.849 87.529 10.82-29.246 1.6536-0.04725 11.906 29.388-11.906-29.34h-1.7481l-6.6618 18.285h13.607"
            clipPath="url(#clipPath865)"
            fill="none"
            stroke="var(--darkGreen)"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5.165"
          />
        </g>
      </svg>
    </div>
  );
};
export const B_Svg: React.FC<Props1> = ({ nameClass }) => {
  return (
    <div className="handwriting">
      <svg
        width="22.882mm"
        height="30.303mm"
        version="1.1"
        viewBox="0 0 22.882 30.303"
        xmlns="http://www.w3.org/2000/svg"
      >
        <clipPath id="clipPath880">
          <path
            d="m74.734 83.888v-30.303h11.369q3.4727 0 5.5604 0.93018 2.1084 0.9095 3.2866 2.8319 1.1989 1.9017 1.1989 3.9894 0 1.943-1.0542 3.6587-1.0542 1.7157-3.1833 2.7699 2.7492 0.80615 4.2168 2.7492 1.4883 1.943 1.4883 4.5889 0 2.1291-0.9095 3.9687-0.88883 1.819-2.2118 2.8112-1.3229 0.99219-3.328 1.509-1.9844 0.49609-4.8783 0.49609zm4.0101-17.57h6.5526q2.6665 0 3.8241-0.3514 1.5296-0.45475 2.2944-1.509 0.78548-1.0542 0.78548-2.6458 0-1.509-0.72347-2.6458-0.72347-1.1576-2.0671-1.571-1.3436-0.43408-4.6095-0.43408h-6.0565zm0 13.994h7.5448q1.943 0 2.7285-0.14469 1.3849-0.24805 2.3151-0.82682 0.93018-0.57878 1.5296-1.6743 0.59945-1.1162 0.59945-2.5631 0-1.695-0.86816-2.9352-0.86816-1.2609-2.4185-1.757-1.5296-0.51676-4.4235-0.51676h-7.0073z"
            strokeWidth=".26458"
          />
        </clipPath>
        <g transform="translate(-74.734 -53.585)">
          <path
            className={nameClass === "Restart" ? "" : "text"}
            d="m76.86 55.097 0.23624 28.49-0.23624-30.049 0.14174 1.9371 3.449-0.37798h3.2128l3.6853 0.09449 3.4018 0.47247 1.4647 1.0867 1.1812 1.4647 0.70871 1.5592 0.14174 1.6536-0.04725 1.9371-0.7087 1.3229-1.5592 1.3229-1.2757 1.2757-11.576 0.56696 11.056 0.8032 1.5119 0.7087 1.9844 1.5119 1.7009 1.5592 0.42522 1.6064-0.33073 3.1183-0.85045 1.9844-2.0316 1.5592-2.7876 1.2284-8.8352 0.18899-3.6853 0.04725-0.09449-0.04725"
            clipPath="url(#clipPath880)"
            fill="none"
            stroke="var(--darkGreen)"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="4.7"
          />
        </g>
      </svg>
    </div>
  );
};
export const C_Svg: React.FC<Props1> = ({ nameClass }) => {
  return (
    <div className="handwriting">
      <svg
        width="23.662mm"
        height="32.377mm"
        version="1.1"
        viewBox="0 0 23.662 32.377"
        xmlns="http://www.w3.org/2000/svg"
      >
        <clipPath id="clipPath1547">
          <path
            d="m105.37 136.79q3.0706 0 5.238 0.76765 2.1675 0.76764 4.1543 2.3932l-2.7093 3.2512q-2.8448-2.3029-6.4572-2.3029-3.7931 0-6.1863 2.9351-2.3932 2.89-2.3932 9.1214 0 6.1412 2.3481 9.0763 2.3481 2.89 6.2766 2.89 2.2578 0 3.8382-0.67733 1.6256-0.72249 3.206-1.9868l2.5287 3.206q-3.7931 3.7028-9.7536 3.7028-4.0188 0-7.1797-1.8514-3.1609-1.8965-4.9671-5.5541-1.7611-3.6576-1.7611-8.8053 0-5.0574 1.8062-8.715 1.8514-3.6576 5.0123-5.5541 3.1609-1.8965 6.9991-1.8965z"
            strokeWidth=".26458"
          />
        </clipPath>
        <g transform="translate(-91.55 -136.79)">
          <path
            className={nameClass === "Restart" ? "" : "text"}
            d="m112.65 140.78-4.5436-1.9377-3.0736 0.0668-3.3409 0.8018-4.6772 2.4054-2.0045 3.9422-0.80181 4.2763-0.13363 4.6772 0.86862 3.3409 1.4032 4.009 2.4722 3.0068 3.2072 1.2695 6.1472 0.66817 4.4768-1.4032 0.73499-0.86863"
            clipPath="url(#clipPath1547)"
            fill="none"
            stroke="var(--darkGreen)"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5.765"
          />
        </g>
      </svg>
    </div>
  );
};
export const D_Svg: React.FC<Props1> = ({ nameClass }) => {
  return (
    <div className="handwriting">
      <svg
        width="25.053mm"
        height="30.303mm"
        version="1.1"
        viewBox="0 0 25.053 30.303"
        xmlns="http://www.w3.org/2000/svg"
      >
        <clipPath id="clipPath895">
          <path
            d="m74.787 84.248v-30.303h10.439q3.5347 0 5.395 0.43408 2.6045 0.59945 4.4442 2.1704 2.3978 2.0257 3.576 5.1883 1.1989 3.1419 1.1989 7.1934 0 3.452-0.80615 6.1185-0.80615 2.6665-2.0671 4.4235-1.2609 1.7363-2.7699 2.7492-1.4883 0.99219-3.6173 1.509-2.1084 0.51676-4.8576 0.51676zm4.0101-3.576h6.4699q2.9972 0 4.6922-0.5581 1.7157-0.5581 2.7285-1.571 1.4263-1.4263 2.2118-3.8241 0.80615-2.4185 0.80615-5.8498 0-4.7542-1.571-7.2967-1.5503-2.5631-3.7827-3.4313-1.6123-0.62012-5.1883-0.62012h-6.3665z"
            strokeWidth=".26458"
          />
        </clipPath>
        <g transform="translate(-74.787 -53.945)">
          <path
            className={nameClass === "Restart" ? "" : "text"}
            d="m76.44 53.945 0.33073 30.427-0.18899-28.726 3.7325-0.18899 5.3862 0.04725 2.7403 0.47247 3.3073 0.94494 2.5986 1.5592 1.2284 1.7009 1.2284 2.5041 0.8032 2.6931 0.37798 3.8743-0.23624 3.3073-0.99219 3.638-2.2206 2.6458-2.1261 2.4096-3.0238 1.2757-3.827 0.51972-4.4412-0.04725h-3.449l-0.94494 0.09449"
            clipPath="url(#clipPath895)"
            fill="none"
            stroke="var(--darkGreen)"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="4.7"
          />
        </g>
      </svg>
    </div>
  );
};
export const E_Svg: React.FC<Props1> = ({ nameClass }) => {
  return (
    <div className="handwriting">
      <svg
        width="22.614mm"
        height="30.303mm"
        version="1.1"
        viewBox="0 0 22.614 30.303"
        xmlns="http://www.w3.org/2000/svg"
      >
        <clipPath id="clipPath912">
          <path
            d="m80.498 78.608v-30.303h21.911v3.576h-17.901v9.2811h16.764v3.5553h-16.764v10.315h18.603v3.576z"
            strokeWidth=".26458"
          />
        </clipPath>
        <g transform="translate(-80.498 -48.305)">
          <path
            className={nameClass === "Restart" ? "" : "text"}
            d="m82.577 48.305 0.09449 30.38-0.04725-28.915 19.985 0.09449-19.985-0.04725 0.04725 13.182 18.757 0.09449-18.71-0.14174-0.14174 14.221 20.694 0.04725"
            clipPath="url(#clipPath912)"
            fill="none"
            stroke="var(--darkGreen)"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="4.4"
          />
        </g>
      </svg>
    </div>
  );
};
export const F_Svg: React.FC<Props1> = ({ nameClass }) => {
  return (
    <div className="handwriting">
      <svg
        width="22.614mm"
        height="30.303mm"
        version="1.1"
        viewBox="0 0 22.614 30.303"
        xmlns="http://www.w3.org/2000/svg"
      >
        <clipPath id="clipPath929">
          <path
            d="m81.606 78.669v-30.303h20.443v3.576h-16.433v9.3844h14.221v3.576h-14.221v13.767z"
            strokeWidth=".26458"
          />
        </clipPath>
        <g transform="translate(-80.498 -48.305)">
          <path
            className={nameClass === "Restart" ? "" : "text"}
            d="m83.663 49.344 0.14174 29.246-0.09449-28.821 18.474 0.18899-18.426-0.14174 0.09449 13.56 16.017-0.04725"
            clipPath="url(#clipPath929)"
            fill="none"
            stroke="var(--darkGreen)"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="4.4"
          />
        </g>
      </svg>
    </div>
  );
};
export const G_Svg: React.FC<Props1> = ({ nameClass }) => {
  return (
    <div className="handwriting">
      <svg
        width="23.165mm"
        height="32.377mm"
        version="1.1"
        viewBox="0 0 23.165 32.377"
        xmlns="http://www.w3.org/2000/svg"
      >
        <clipPath id="clipPath2837">
          <path
            d="m104.32 169.92q-6.0509 0-9.3924-4.1092-3.2964-4.1543-3.2964-12.102 0-5.1477 1.8062-8.8053 1.8514-3.6576 4.922-5.509 3.1157-1.8514 6.8636-1.8514 3.0254 0 5.1477 0.90312 2.1675 0.85795 4.1092 2.7093l-2.9351 3.0254q-1.445-1.3095-2.9351-1.8965-1.445-0.63218-3.2512-0.63218-3.6124 0-5.9605 2.9351-2.3029 2.89-2.3029 9.1214 0 6.4121 1.8062 9.302 1.8514 2.8448 5.6445 2.8448 2.89 0 5.1477-1.3095v-8.5796h-5.2832l-0.54187-4.0188h10.928v15.127q-2.3932 1.3547-4.922 2.1223-2.4836 0.72249-5.5541 0.72249z"
            strokeWidth=".26458"
          />
        </clipPath>
        <g transform="translate(-91.629 -137.54)">
          <path
            className={nameClass === "Restart" ? "" : "text"}
            d="m111.66 141.32-3.6853-1.8899h-1.9844l-3.3073 0.37797-3.5908 1.0394-2.2679 2.8348-1.7954 2.7403-0.94494 2.5513-0.28348 4.4412 0.47247 3.9688 0.56696 3.4018 1.5119 2.8348 1.8899 2.0789 2.2679 1.3229 2.3624 0.56696 2.6458 0.0945 2.1734-0.37798 2.9293-0.66146 0.94494-0.75595 1.0394-2.6458 0.18899-1.9844 0.0945-1.7954v-1.4174l-0.18898-3.0238-0.56697-1.6064h-7.276"
            clipPath="url(#clipPath2837)"
            fill="none"
            stroke="var(--darkGreen)"
            strokeWidth="5.965"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </svg>
    </div>
  );
};
export const H_Svg: React.FC<Props1> = ({ nameClass }) => {
  return (
    <div className="handwriting">
      <svg
        width="23.771mm"
        height="30.303mm"
        version="1.1"
        viewBox="0 0 23.771 30.303"
        xmlns="http://www.w3.org/2000/svg"
      >
        <clipPath id="clipPath959">
          <path
            d="m80.11 79.823v-30.303h4.0101v12.444h15.751v-12.444h4.0101v30.303h-4.0101v-14.283h-15.751v14.283z"
            strokeWidth=".26458"
          />
        </clipPath>
        <g transform="translate(-80.061 -49.421)">
          <path
            className={nameClass === "Restart" ? "" : "text"}
            d="m81.664 49.755 0.26727 30.268-0.26727-34.611v-1.3363l19.912-0.13363 0.60135 36.015-0.13363 4.3431-19.778-4.4768-0.26727-16.103 19.243 0.13363"
            clipPath="url(#clipPath959)"
            fill="none"
            stroke="var(--darkGreen)"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="4.565"
          />
        </g>
      </svg>
    </div>
  );
};
export const I_Svg: React.FC<Props1> = ({ nameClass }) => {
  return (
    <div className="handwriting">
      <svg
        width="23.771mm"
        height="30.303mm"
        version="1.1"
        viewBox="0 0 23.771 30.303"
        xmlns="http://www.w3.org/2000/svg"
      >
        <clipPath id="clipPath974">
          <path
            d="m89.755 79.823v-30.303h4.0101v30.303z"
            strokeWidth=".26458"
          />
        </clipPath>
        <g transform="translate(-80.061 -49.421)">
          <path
            className={nameClass === "Restart" ? "" : "text"}
            d="m91.487 49.487 0.4006 30.472"
            clipPath="url(#clipPath974)"
            fill="none"
            stroke="var(--darkGreen)"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="4.5284"
          />
        </g>
      </svg>
    </div>
  );
};
export const J_Svg: React.FC<Props1> = ({ nameClass }) => {
  return (
    <div className="handwriting">
      <svg
        width="20.23mm"
        height="31.744mm"
        version="1.1"
        viewBox="0 0 20.23 31.744"
        xmlns="http://www.w3.org/2000/svg"
      >
        <clipPath id="clipPath2943">
          <path
            d="m115.92 158.63q0 4.922-2.8448 8.128-2.7996 3.1609-8.5344 3.1609-4.922 0-8.8505-2.89l2.1223-3.2964q1.7611 1.1289 3.3415 1.6256 1.5804 0.45155 3.206 0.45155 2.9351 0 4.6059-1.8965 1.7159-1.8965 1.7159-5.3735v-16.346h-9.3472v-4.0188h14.585z"
            strokeWidth=".26458"
          />
        </clipPath>
        <g transform="translate(-95.691 -138.18)">
          <path
            className={nameClass === "Restart" ? "" : "text"}
            d="m113.33 142.05-0.13363 4.3431v10.624l-0.46772 5.6795-2.0713 2.5391-3.1404 1.9377-3.4077 0.73499-4.0759-0.60136-3.5413-1.8041"
            clipPath="url(#clipPath2943)"
            fill="none"
            stroke="var(--darkGreen)"
            strokeWidth="6.065"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </svg>
    </div>
  );
};
export const K_Svg: React.FC<Props1> = ({ nameClass }) => {
  return (
    <div className="handwriting">
      <svg
        width="20.154mm"
        height="32.639mm"
        version="1.1"
        viewBox="0 0 20.154 32.639"
        xmlns="http://www.w3.org/2000/svg"
      >
        <clipPath id="clipPath1036">
          <path
            d="m84.913 48.733 0.0026 0.0072c0.02739-0.0015 0.05478-0.0026 0.08217-0.0041-0.02825-0.0011-0.0565-0.0019-0.08475-0.0031zm3.575 1.0222c0.0279 0.0043 0.05579 0.0088 0.08372 0.01292 0.10386-7.44e-4 0.20773-0.0024 0.31161-0.0031v-0.0098c-0.13178 2.5e-5 -0.26356 8e-6 -0.39532 0zm0.39532 7.2456c-0.04492 0.0087-0.09861 0.02063-0.14366 0.02894 0.06238-0.0017 0.0872-0.0016 0.14366-0.0031zm-0.0016 0.71779c-0.0203 0.0065-0.03786 0.01908-0.05633 0.02997 0.0083-4.76e-4 0.01029 2.11e-4 0.01499-0.0016 0.01569-0.0059 0.0573-0.03354 0.04134-0.02842zm-0.06718 0.03566c-0.0019 0.0011-0.0038 0.0021-0.0057 0.0031-0.04574 0.02196-0.09212 0.04388-0.13901 0.06408-0.01085 0.0096-0.02239 0.01824-0.03359 0.02739-0.0038 0.01107-0.01063 0.02246-0.01705 0.03359 0.06431-0.04906 0.14919-0.08803 0.26407-0.1018v-0.02015c-0.02294-2e-3 -0.046-3e-3 -0.06873-0.0062zm-2.5404 0.50643c-0.0095-0.0032 0.01737 0.01902 0.01189 0.02739-6e-3 0.0091-0.0217 0.0024-0.03256 0.0036-0.23824 0.01587-0.47699 0.02857-0.71572 0.01602-0.04831-2.91e-4 -0.18812 1e-3 -0.27078 1e-3 -0.15185-2.92e-4 -0.30383-4.65e-4 -0.45578-5.15e-4v22.75h4.0721v-22.623c-0.0832-0.01-0.14796-0.03468-0.20464-0.0646-0.16408 0.0079-0.32827 0.0051-0.49248 0.0041-0.08588-3.7e-4 -0.17147 5.56e-4 -0.25735 5.29e-4 -0.0635-1.14e-4 -0.12718-1.8e-5 -0.19069 0-0.117 4e-6 -0.23388 0-0.35088 0-0.21025 2e-6 -0.42072 1e-6 -0.63097 0-0.1367 0.0061-0.23282-0.0049-0.37-0.05426-0.04008-0.01444-0.07171-0.04704-0.11214-0.06046zm2.6091 11.057 10.583 11.741h5.4984l-11.121-12.34 8.8677-10.108h-4.4028zm-0.24903-10.959c-0.01276-0.0031 0.01712 4e-3 0.03824 0.0088-0.0011-6.08e-4 -2e-3 -0.0015-0.0031-0.0021-0.01232-0.0027-0.02192-0.0036-0.03514-0.0067z"
            strokeWidth=".26458"
          />
        </clipPath>
        <g transform="translate(-84.811 -48.419)">
          <path
            className={nameClass === "Restart" ? "" : "text"}
            d="m86.274 58.26 0.40793 22.832 0.06682-26.927h14.566l-0.86862 4.5436-9.154 10.29 11.092 12.294"
            clipPath="url(#clipPath1036)"
            fill="none"
            stroke="var(--darkGreen)"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="4.4"
          />
        </g>
      </svg>
    </div>
  );
};
export const L_Svg: React.FC<Props1> = ({ nameClass }) => {
  return (
    <div className="handwriting">
      <svg
        width="19.191mm"
        height="31.157mm"
        version="1.1"
        viewBox="0 0 19.191 31.157"
        xmlns="http://www.w3.org/2000/svg"
      >
        <clipPath id="clipPath3037">
          <path
            d="m92.547 173.33v-31.157h5.1929v26.777h13.998l-0.58703 4.3801z"
            strokeWidth=".26458"
          />
        </clipPath>
        <g transform="translate(-92.547 -142.17)">
          <path
            className={nameClass === "Restart" ? "" : "text"}
            d="m95.086 142v28.899l16.337 0.16704"
            clipPath="url(#clipPath3037)"
            fill="none"
            stroke="var(--darkGreen)"
            strokeWidth="5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </svg>
    </div>
  );
};
export const M_Svg: React.FC<Props1> = ({ nameClass }) => {
  return (
    <>
      <div className="handwriting">
        <svg
          width="24.836mm"
          height="31.157mm"
          version="1.1"
          viewBox="0 0 24.836 31.157"
          xmlns="http://www.w3.org/2000/svg"
        >
          <clipPath id="clipPath3052">
            <path
              d="m114.3 173.24h-4.8316l-0.72249-15.172q-0.13547-2.9803-0.13547-5.7348 0-3.5673 0.13547-5.6445l-4.6059 21.043h-4.5607l-4.922-21.043q0.18062 2.3029 0.18062 7.0894 0 2.89-0.04516 4.3349l-0.63218 15.127h-4.6962l1.7611-31.157h6.2766l4.4704 20.862 4.2446-20.862h6.3218z"
              strokeWidth=".265"
            />
          </clipPath>
          <g transform="translate(-89.467 -142.08)">
            <path
              className={nameClass === "Restart" ? "" : "text"}
              d="m91.504 173.75 1.5034-31.003h2.6727l5.2786 25.624 1.637-0.10022 5.0781-25.524 2.7729-0.0334 1.3698 31.103"
              clipPath="url(#clipPath3052)"
              fill="none"
              stroke="var(--darkGreen)"
              strokeWidth="4.3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </svg>
      </div>
    </>
  );
};
export const N_Svg: React.FC<Props1> = ({ nameClass }) => {
  return (
    <>
      <div className="handwriting">
        <svg
          width="20.681mm"
          height="31.157mm"
          version="1.1"
          viewBox="0 0 20.681 31.157"
          xmlns="http://www.w3.org/2000/svg"
        >
          <clipPath id="clipPath3067">
            <path
              d="m95.465 146.85q0.72249 7.4055 0.72249 12.373v14.134h-4.651v-31.157h6.8185l9.9342 26.506q-0.31609-3.3415-0.54187-6.0057-0.18062-2.7093-0.18062-6.3218v-14.179h4.651v31.157h-6.8185z"
              strokeWidth=".26458"
            />
          </clipPath>
          <g transform="translate(-91.537 -142.2)">
            <path
              className={nameClass === "Restart" ? "" : "text"}
              d="m93.568 173.33-0.18899-31.23 2.7403 0.0472 11.528 31.136h3.2128l-0.80319-31.136"
              clipPath="url(#clipPath3067)"
              fill="none"
              stroke="var(--darkGreen)"
              strokeWidth="5.2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </svg>
      </div>
    </>
  );
};
export const O_Svg: React.FC<Props1> = ({ nameClass }) => {
  return (
    <>
      <div className="handwriting">
        <svg
          width="22.944mm"
          height="27.802mm"
          version="1.1"
          viewBox="0 0 22.944 27.802"
          xmlns="http://www.w3.org/2000/svg"
        >
          <clipPath id="clipPath1049">
            <path
              d="m82.218 66.486q0-6.3459 3.1006-10.108 3.1213-3.7827 8.3716-3.7827 5.2503 0 8.3509 3.7827 3.1212 3.762 3.1212 10.108 0 6.3665-3.1212 10.149-3.1006 3.762-8.3509 3.762-5.2503 0-8.3716-3.762-3.1006-3.7827-3.1006-10.149zm4.3408 0q0 5.209 1.8397 8.0408 1.8604 2.8112 5.2917 2.8112 3.4313 0 5.2917-2.8112 1.8604-2.8319 1.8604-8.0408 0-5.1883-1.8604-7.9995-1.8604-2.8319-5.2917-2.8319-3.4313 0-5.2917 2.8319-1.8397 2.8112-1.8397 7.9995z"
              strokeWidth=".26458"
            />
          </clipPath>
          <g transform="translate(-82.218 -52.595)">
            <path
              className={nameClass === "Restart" ? "" : "text"}
              d="m90.77 54.466-4.2763 3.4077-1.4032 2.7395-0.46772 2.6059-0.4009 2.3386v2.4722l0.4009 2.6727 0.93544 2.5391 1.0023 1.8709 1.5368 1.9377 1.8041 1.0691 1.8041 0.66817 2.6727 0.20045 1.1359-0.13363 1.5368-0.73499 0.93544-0.60136 1.3363-1.0023 1.0023-1.2027 1.1359-1.5368 0.93544-1.5368 0.0668-1.2027 0.26727-2.205v-2.4054l0.0668-2.5391-0.33408-2.6059-0.93545-2.205-1.0023-1.9377-1.8041-1.4032-2.6059-1.3363-2.2718-0.4009z"
              clipPath="url(#clipPath1049)"
              fill="none"
              stroke="var(--darkGreen)"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="5.1"
            />
          </g>
        </svg>
      </div>
    </>
  );
};
export const P_Svg: React.FC<Props1> = ({ nameClass }) => {
  return (
    <>
      <div className="handwriting">
        <svg
          width="19.203mm"
          height="26.52mm"
          version="1.1"
          viewBox="0 0 19.203 26.52"
          xmlns="http://www.w3.org/2000/svg"
        >
          <clipPath id="clipPath1064">
            <path
              d="m85.126 79.723v-26.52h8.6196q4.6095 0 6.5732 0.82682 4.0101 1.7363 4.0101 6.6973 0 4.1755-3.2453 6.5526-3.2246 2.3771-8.909 2.3771h-2.9766v10.067zm4.0721-13.126h2.5425q8.2682 0 8.2682-5.7878 0-2.4391-1.571-3.4933-1.5503-1.0542-5.1883-1.0542h-4.0514z"
              strokeWidth=".26458"
            />
          </clipPath>
          <g transform="translate(-85.126 -53.203)">
            <path
              className={nameClass === "Restart" ? "" : "text"}
              d="m86.997 53.403 0.13364 26.46-0.06682-25.057 7.7508 0.13364 3.0068 0.33409 2.3386 0.53454 1.47 1.9377 0.46773 2.0713-0.0668 2.4722-1.0023 2.4722-1.6704 1.9377-2.5391 1.2027-3.1404 0.73499-3.8086-0.20045-2.5391 0.4009"
              clipPath="url(#clipPath1064)"
              fill="none"
              stroke="var(--darkGreen)"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="4.4"
            />
          </g>
        </svg>
      </div>
    </>
  );
};
export const Q_Svg: React.FC<Props1> = ({ nameClass }) => {
  return (
    <>
      <div className="handwriting">
        <svg
          width="25.648mm"
          height="39.601mm"
          version="1.1"
          viewBox="0 0 25.648 39.601"
          xmlns="http://www.w3.org/2000/svg"
        >
          <clipPath id="clipPath3138">
            <path
              d="m115.86 157.17q0 5.1026-1.5353 8.715-1.4901 3.5673-4.9671 5.4187 3.477 0.67734 5.4187 2.3029 1.9868 1.6708 3.1609 5.1477l-4.8316 1.8062q-1.0386-2.5739-2.2126-4.1543-1.174-1.5353-2.9351-2.3481-1.7611-0.81281-4.3801-0.81281-5.4638 0-8.3989-4.2446-2.89-4.2898-2.89-11.786 0-7.4507 3.0254-11.831 3.0706-4.4252 8.7602-4.4252 5.7348 0 8.7602 4.3349 3.0254 4.3349 3.0254 11.876zm-5.4638 0q0-6.1863-1.4901-9.1214-1.445-2.9803-4.8316-2.9803-3.3867 0-4.8768 2.9803-1.445 2.9803-1.445 9.1666 0 6.1412 1.4901 9.1214 1.5353 2.9351 4.8317 2.9351 3.3867 0 4.8316-2.9351 1.4901-2.9803 1.4901-9.1666z"
              strokeWidth=".26458"
            />
          </clipPath>
          <g transform="translate(-92.287 -140.96)">
            <path
              className={nameClass === "Restart" ? "" : "text"}
              d="m104.51 142.9-2.205 0.13364-2.205 1.0023-1.47 1.4032-1.7373 1.6704-0.66817 1.7372-0.26727 1.5368-0.46772 2.0713-0.33409 1.8041-0.33409 2.0713v1.2695l0.06682 1.8709 0.33409 2.0713 0.60136 1.6704 0.66817 2.4722 0.73499 1.6704 1.3363 1.6704 1.4032 1.0023 1.9377 0.60136 1.8709-0.13364 2.2718-0.0668 2.0713-1.0023 2.5391-1.5368 0.53454-1.6036 0.66818-2.5391 0.53453-2.7395 0.46773-2.8063 0.20045-3.0736-0.40091-3.0736-0.53453-2.2718-0.66818-2.6727-1.3364-2.205-1.3363-1.1359-1.7373-0.60136-2.6059 0.26727 2.8063-0.20045 1.8709 0.73499 1.2695 1.2027 1.0691 2.1382 1.0023 3.2072 0.60136 3.274 0.0668 2.94-0.60136 4.41-1.0691 4.6104-0.73499 2.2718-2.8732 1.5368-1.7372 0.80181-1.3364 0.0668 1.4032 0.86863 2.3386 0.60136 1.8041 1.2695 1.7372 1.8041 1.8041 1.2027 1.2695 2.1382 0.60135 0.86862"
              clipPath="url(#clipPath3138)"
              fill="none"
              stroke="var(--darkGreen)"
              strokeWidth="6"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </svg>
      </div>
    </>
  );
};
export const R_Svg: React.FC<Props1> = ({ nameClass }) => {
  return (
    <>
      <div className="handwriting">
        <svg
          width="21.063mm"
          height="26.52mm"
          version="1.1"
          viewBox="0 0 21.063 26.52"
          xmlns="http://www.w3.org/2000/svg"
        >
          <clipPath id="clipPath1079">
            <path
              d="m86.524 79.262v-26.52h7.4207q4.6302 0 6.4285 0.76481 3.8447 1.5916 3.8447 5.6224 0 2.7699-1.3849 4.6302-1.3849 1.8397-4.6302 3.4313l9.3844 12.072h-4.9403l-8.3716-11.038h-3.6794v11.038zm4.0721-14.118h2.1497q3.2866 0 5.209-1.4469 1.9224-1.4469 1.9224-3.9067 0-2.2944-1.4469-3.1419t-5.333-0.84749h-2.5011z"
              strokeWidth=".26458"
            />
          </clipPath>
          <g transform="translate(-86.524 -52.741)">
            <path
              className={nameClass === "Restart" ? "" : "text"}
              d="m88.367 52.836 0.14174 26.458-0.14174-25.041 4.7719-0.04725 4.9137 0.28348 2.1261 0.94494 1.4174 1.0394 0.51972 2.1734-0.0945 2.1734-0.85045 2.2206-2.0316 1.7009-3.0711 1.4174-3.7798 0.56696h-2.4096l3.5435-0.14174 1.7009-0.33073 1.9844 2.3151 1.9371 2.5041 6.2839 8.3155"
              clipPath="url(#clipPath1079)"
              fill="none"
              stroke="var(--darkGreen)"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="4.4"
            />
          </g>
        </svg>
      </div>
    </>
  );
};
export const S_Svg: React.FC<Props1> = ({ nameClass }) => {
  return (
    <>
      <div className="handwriting">
        <svg
          width="23.391mm"
          height="32.377mm"
          version="1.1"
          viewBox="0 0 23.391 32.377"
          xmlns="http://www.w3.org/2000/svg"
        >
          <clipPath id="clipPath857">
            <path
              d="m111.01 130.01q0 2.7996-1.445 4.922-1.3998 2.1223-4.1092 3.2964-2.6642 1.174-6.3669 1.174-7.2249 0-11.47-4.1543l2.89-3.206q3.5221 3.2512 8.5796 3.2512 2.7996 0 4.6059-1.3095 1.8514-1.3095 1.8514-3.7028 0-1.3998-0.58703-2.3481-0.54186-0.94827-1.8965-1.6708-1.3547-0.76764-3.8834-1.5353-5.1929-1.5353-7.6313-3.6576-2.4384-2.1223-2.4384-5.5993 0-2.4836 1.3547-4.3801 1.3998-1.9417 3.8382-2.9803 2.4384-1.0837 5.509-1.0837 6.2766 0 10.431 3.7479l-2.7545 3.1157q-3.206-2.7545-7.3604-2.7545-2.5287 0-4.1092 1.0386-1.5353 1.0386-1.5353 2.9803 0 1.2192 0.63218 2.0772 0.67733 0.85796 2.2126 1.5804 1.5804 0.67733 4.4253 1.5353 4.5156 1.3547 6.8636 3.477 2.3932 2.1223 2.3932 6.1863z"
              strokeWidth=".26458"
            />
          </clipPath>
          <g transform="translate(-87.616 -107.02)">
            <path
              className={nameClass === "Restart" ? "" : "text"}
              d="m108.97 111.89-3.7798-2.4096-2.3151-0.89769-1.7009-0.18899h-1.9371l-2.1261 0.23624-2.7403 0.85044-1.1812 1.2757-0.70871 1.3229-0.33073 1.6064-0.18899 1.7009 0.23624 1.5119 1.1812 1.9371 1.1812 0.8032 1.8899 1.1339 1.6064 0.75595 2.7403 1.0394s2.2206 0.99219 2.3624 1.1812c0.14174 0.18899 2.5513 1.4174 2.5513 1.4174l1.7481 1.5119 1.0394 1.7954-0.0472 1.8899-0.28349 1.3229-0.47247 1.0867-1.1812 1.1812-1.4647 1.1339-1.8899 0.66146-2.2679 0.51971-2.5513 0.14174-2.1734-0.18898-2.2679-0.23624-1.9844-0.66146-1.9844-0.99218-0.99219-0.75596"
              clipPath="url(#clipPath857)"
              fill="none"
              stroke="var(--darkGreen)"
              strokeWidth="6.1"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </svg>
      </div>
    </>
  );
};
export const T_Svg: React.FC<Props1> = ({ nameClass }) => {
  return (
    <>
      <div className="handwriting">
        <svg
          width="24.474mm"
          height="26.52mm"
          version="1.1"
          viewBox="0 0 24.474 26.52"
          xmlns="http://www.w3.org/2000/svg"
        >
          <clipPath id="clipPath1094">
            <path
              d="m95.895 78.8v-23.461h-10.191v-3.0592h24.474v3.0592h-10.191v23.461z"
              strokeWidth=".26458"
            />
          </clipPath>
          <g transform="translate(-85.705 -52.28)">
            <path
              className={nameClass === "Restart" ? "" : "text"}
              d="m97.932 52.347 0.06682 26.527-0.06682-28.865 0.06682-2.6727-17.573-0.33409 2.8731 7.0158 2.4054-0.06682 24.455-0.13364"
              clipPath="url(#clipPath1094)"
              fill="none"
              stroke="var(--darkGreen)"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="4.2"
            />
          </g>
        </svg>
      </div>
    </>
  );
};
export const U_Svg: React.FC<Props1> = ({ nameClass }) => {
  return (
    <>
      <div className="handwriting">
        <svg
          width="21.584mm"
          height="31.744mm"
          version="1.1"
          viewBox="0 0 21.584 31.744"
          xmlns="http://www.w3.org/2000/svg"
        >
          <clipPath id="clipPath887">
            <path
              d="m110.41 128.25q0 3.1609-1.3095 5.5993-1.2644 2.4384-3.7028 3.8382-2.4384 1.3547-5.8251 1.3547-5.1026 0-7.9474-2.9351-2.7996-2.9351-2.7996-7.8571v-20.952h5.238v19.959q0 3.8834 1.3095 5.7799 1.3547 1.8514 4.1995 1.8514 2.89 0 4.1995-1.8514 1.3547-1.8965 1.3547-5.7799v-19.959h5.2832z"
              strokeWidth=".26458"
            />
          </clipPath>
          <g transform="translate(-88.828 -107.3)">
            <path
              className={nameClass === "Restart" ? "" : "text"}
              d="m91.285 106.87-0.09449 4.205 0.04725 3.9215-0.04725 4.3467-0.09449 5.7641 0.28348 3.9215 0.75595 2.8348 1.6064 2.9766 1.5119 1.1812 1.6064 0.47247 2.5041 0.14174 2.3624-0.18899 2.1261-0.75595 2.1734-1.4174 0.7087-1.3229 0.61422-1.7009 0.18898-1.6536 0.14175-2.4568-0.0473-2.2679 0.0473-3.0238-0.0473-2.9293 0.0945-2.9293-0.14174-2.5513 0.0473-2.1261 0.23623-2.8348-0.14174-1.7481"
              clipPath="url(#clipPath887)"
              fill="none"
              stroke="var(--darkGreen)"
              strokeWidth="6.1"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </svg>
      </div>
    </>
  );
};
export const V_Svg: React.FC<Props1> = ({ nameClass }) => {
  return (
    <>
      <div className="handwriting">
        <svg
          width="25.739mm"
          height="31.157mm"
          version="1.1"
          viewBox="0 0 25.739 31.157"
          xmlns="http://www.w3.org/2000/svg"
        >
          <clipPath id="clipPath902">
            <path
              d="m101 132.4 7.4507-26.19h5.3284l-9.8891 31.157h-5.9605l-9.8891-31.157h5.5993z"
              strokeWidth=".26458"
            />
          </clipPath>
          <g transform="translate(-88.036 -106.21)">
            <path
              className={nameClass === "Restart" ? "" : "text"}
              d="m90.682 106.07 10.205 31.325 10.347-31.278"
              clipPath="url(#clipPath902)"
              fill="none"
              stroke="var(--darkGreen)"
              strokeWidth="5.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </svg>
      </div>
    </>
  );
};
export const X_Svg: React.FC<Props1> = ({ nameClass }) => {
  return (
    <>
      <div className="handwriting">
        <svg
          width="24.97mm"
          height="26.52mm"
          version="1.1"
          viewBox="0 0 24.97 26.52"
          xmlns="http://www.w3.org/2000/svg"
        >
          <clipPath id="clipPath1109">
            <path
              d="m85.331 75.8 10.397-13.498-9.5085-13.022h4.8162l7.1727 9.8185 7.5861-9.8185h3.9688l-9.6325 12.444 10.17 14.077h-4.8369l-7.8341-10.79-8.3302 10.79z"
              strokeWidth=".26458"
            />
          </clipPath>
          <g transform="translate(-85.331 -49.279)">
            <path
              className={nameClass === "Restart" ? "" : "text"}
              d="m88.872 49.413 19.177 26.527 3.9422-0.13364 2.94-28.063-3.0068-2.8063-4.0759 4.2763-20.58 26.994"
              clipPath="url(#clipPath1109)"
              fill="none"
              stroke="var(--darkGreen)"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="4.1"
            />
          </g>
        </svg>
      </div>
    </>
  );
};
export const W_Svg: React.FC<Props1> = ({ nameClass }) => {
  return (
    <>
      <div className="handwriting">
        <svg
          width="26.913mm"
          height="31.157mm"
          version="1.1"
          viewBox="0 0 26.913 31.157"
          xmlns="http://www.w3.org/2000/svg"
        >
          <clipPath id="clipPath932">
            <path
              d="m114.28 106.21-4.2898 31.157h-6.1412l-3.0706-23.075-3.206 23.075h-6.2315l-3.9737-31.157h5.0574l2.4384 26.1 3.3415-22.487h5.3735l3.1157 22.487 2.89-26.1z"
              strokeWidth=".26458"
            />
          </clipPath>
          <g transform="translate(-87.367 -106.21)">
            <path
              className={nameClass === "Restart" ? "" : "text"}
              d="m89.576 105.83 3.8743 29.529h2.0789l3.7292-23.954 2.4072 0.0472 3.9225 23.893 2.6506-0.01 3.4963-29.364"
              clipPath="url(#clipPath932)"
              fill="none"
              stroke="var(--darkGreen)"
              strokeWidth="4.665"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </svg>
      </div>
    </>
  );
};
export const Y_Svg: React.FC<Props1> = ({ nameClass }) => {
  return (
    <>
      <div className="handwriting">
        <svg
          width="24.701mm"
          height="26.52mm"
          version="1.1"
          viewBox="0 0 24.701 26.52"
          xmlns="http://www.w3.org/2000/svg"
        >
          <clipPath id="clipPath1124">
            <path
              d="m95.658 75.405v-10.852l-10.067-15.668h4.6922l7.7101 12.237 8.4336-12.237h3.8654l-10.563 15.668v10.852z"
              strokeWidth=".26458"
            />
          </clipPath>
          <g transform="translate(-85.591 -48.884)">
            <path
              className={nameClass === "Restart" ? "" : "text"}
              d="m87.863 48.751 9.9558 15.702-0.13364 10.624 5.7463 0.33409 11.025-26.192 0.66818-2.6059-5.1449-1.3363-12.294 18.642"
              clipPath="url(#clipPath1124)"
              fill="none"
              stroke="var(--darkGreen)"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="4.4"
            />
          </g>
        </svg>
      </div>
    </>
  );
};
export const Z_Svg: React.FC<Props1> = ({ nameClass }) => {
  return (
    <>
      <div className="handwriting">
        <svg
          width="21.72mm"
          height="31.157mm"
          version="1.1"
          viewBox="0 0 21.72 31.157"
          xmlns="http://www.w3.org/2000/svg"
        >
          <clipPath id="clipPath962">
            <path
              d="m95.872 131.76h15.714l-0.54187 4.3349h-21.178v-4.064l15.85-22.849h-14.224v-4.2446h20.004v4.1092z"
              strokeWidth=".26458"
            />
          </clipPath>
          <g transform="translate(-89.866 -104.94)">
            <path
              className={nameClass === "Restart" ? "" : "text"}
              d="m91.851 107.02h17.009l0.85044 0.0945-18.048 27.025 19.182-0.0945"
              clipPath="url(#clipPath962)"
              fill="none"
              stroke="var(--darkGreen)"
              strokeWidth="5.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </svg>
      </div>
    </>
  );
};
