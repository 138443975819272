import React, { createContext, useState, useContext } from "react";

const Letter = createContext({});

export default function LetterWritingProvider({ children }: any) {
  const [letterAux, setLetterAux] = useState("A");
  const [wordAux, setWordAux] = useState("");
  return (
    <Letter.Provider
      value={{ letterAux, setLetterAux, wordAux, setWordAux } as any}
    >
      {children}
    </Letter.Provider>
  );
}

export function useLetterWritingContext() {
  const context = useContext(Letter);
  const { letterAux, setLetterAux, wordAux, setWordAux }: any = context;
  return { letterAux, setLetterAux, wordAux, setWordAux };
}
