import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import UserPageProvider from "./context/PagesIndicator";
ReactDOM.render(
  <React.StrictMode>
    <UserPageProvider>
      <App />
    </UserPageProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
