import React, { createContext, useState, useContext } from "react";

const userPage = createContext({});

export default function UserPageProvider({ children }: any) {
  const [activePage, setActivePage] = useState("MenuIntroduction");

  return (
    <userPage.Provider value={{ activePage, setActivePage } as any}>
      {children}
    </userPage.Provider>
  );
}

export function useUserPageContext() {
  const context = useContext(userPage);
  const { activePage, setActivePage }: any = context;
  return { activePage, setActivePage };
}
