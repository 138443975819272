import React, { useState } from "react";
import { Container, MyBox } from "./styles";
import Grid from "@material-ui/core/Grid";
import { Howl, Howler } from "howler";
import { motion } from "framer-motion";

import A_Sound from "../../../../sounds/lettersSound/A_sound.mp3";
import B_Sound from "../../../../sounds/lettersSound/B_sound.mp3";
import C_Sound from "../../../../sounds/lettersSound/C_sound.mp3";
import D_Sound from "../../../../sounds/lettersSound/D_sound.mp3";
import E_Sound from "../../../../sounds/lettersSound/E_sound.mp3";
import F_Sound from "../../../../sounds/lettersSound/F_sound.mp3";
import G_Sound from "../../../../sounds/lettersSound/G_sound.mp3";
import H_Sound from "../../../../sounds/lettersSound/H_sound.mp3";
import I_Sound from "../../../../sounds/lettersSound/I_sound.mp3";
import J_Sound from "../../../../sounds/lettersSound/J_sound.mp3";
import K_Sound from "../../../../sounds/lettersSound/K_sound.mp3";
import L_Sound from "../../../../sounds/lettersSound/L_sound.mp3";
import M_Sound from "../../../../sounds/lettersSound/M_sound.mp3";
import N_Sound from "../../../../sounds/lettersSound/N_sound.mp3";
import O_Sound from "../../../../sounds/lettersSound/O_sound.mp3";
import P_Sound from "../../../../sounds/lettersSound/P_sound.mp3";
import Q_Sound from "../../../../sounds/lettersSound/Q_sound.mp3";
import R_Sound from "../../../../sounds/lettersSound/R_sound.mp3";
import S_Sound from "../../../../sounds/lettersSound/S_sound.mp3";
import T_Sound from "../../../../sounds/lettersSound/T_sound.mp3";
import U_Sound from "../../../../sounds/lettersSound/U_sound.mp3";
import V_Sound from "../../../../sounds/lettersSound/V_sound.mp3";
import X_Sound from "../../../../sounds/lettersSound/X_sound.mp3";
import W_Sound from "../../../../sounds/lettersSound/W_sound.mp3";
import Y_Sound from "../../../../sounds/lettersSound/Y_sound.mp3";
import Z_Sound from "../../../../sounds/lettersSound/Z_sound.mp3";

const Letters: React.FC = () => {
  const [playing, setPlaying] = useState(false);
  const [letterAux, setLetterAux] = useState("");

  var letterList = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
  ];
  const audioClips = [
    { sound: A_Sound },
    { sound: B_Sound },
    { sound: C_Sound },
    { sound: D_Sound },
    { sound: E_Sound },
    { sound: F_Sound },
    { sound: G_Sound },
    { sound: H_Sound },
    { sound: I_Sound },
    { sound: J_Sound },
    { sound: K_Sound },
    { sound: L_Sound },
    { sound: M_Sound },
    { sound: N_Sound },
    { sound: O_Sound },
    { sound: P_Sound },
    { sound: Q_Sound },
    { sound: R_Sound },
    { sound: S_Sound },
    { sound: T_Sound },
    { sound: U_Sound },
    { sound: V_Sound },
    { sound: W_Sound },
    { sound: X_Sound },
    { sound: Y_Sound },
    { sound: Z_Sound },
  ];
  const SoundPlay = (src: any) => {
    if (playing) {
      console.log("Double Click Protection");
    } else {
      const sound = new Howl({
        src,
        html5: true,
        onplay: function () {
          setPlaying(true);
        },
        onend: function () {
          setPlaying(false);
        },
      });
      sound.play();
    }
  };
  Howler.volume(1.0);
  return (
    <>
      <div style={{ overflow: "hidden", maxWidth: "1200px", margin: "auto" }}>
        <Container>
          <Grid container spacing={3}>
            {letterList.map((name, index) => (
              <Grid item xs={6} sm={4} md={4} lg={3} xl={3} key={index}>
                <MyBox
                  onClick={() => {
                    SoundPlay(audioClips[index].sound);
                    setLetterAux(name);
                  }}
                  style={{ position: "relative" }}
                >
                  {letterAux === name ? (
                    <motion.div
                      animate={
                        !playing ? { display: "none" } : { width: "100%" }
                      }
                      transition={{ ease: "circOut", duration: 0.4 }}
                    />
                  ) : (
                    ""
                  )}

                  <span>{name}</span>
                </MyBox>
              </Grid>
            ))}
          </Grid>
        </Container>
      </div>
    </>
  );
};

export default Letters;
