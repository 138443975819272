import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  padding-top: 3%;
  @media (max-width: 768px) {
    padding-top: 15%;
  }
  text-align: center;

  > img {
    width: 200px;
  }
`;
export const FirstTitle = styled.div`
  margin-top: 5%;
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 44px;
  text-align: center;
  color: var(--fontWhite);
  @media (max-width: 500px) {
    font-size: 28px;
    line-height: 130%;
    margin-top: 15%;
  }
`;
