import React, { createContext, useState, useContext } from "react";

const Letter = createContext({});

export default function LetterOutlineProvider({ children }: any) {
  const [letterAux, setLetterAux] = useState("A");
  return (
    <Letter.Provider value={{ letterAux, setLetterAux } as any}>
      {children}
    </Letter.Provider>
  );
}

export function useLetterOutlineContext() {
  const context = useContext(Letter);
  const { letterAux, setLetterAux }: any = context;
  return { letterAux, setLetterAux };
}
