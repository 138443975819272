import React from "react";

import {
  Container,
  TopContent,
  ArrowBack,
  TextContent,
  Title,
  Subtitle,
} from "./styles";
import Logo from "../../../../img/logoAplique.png";
import { useUserPageContext } from "../../../../context/PagesIndicator";
const Header: React.FC = () => {
  const { setActivePage } = useUserPageContext();
  return (
    <>
      <Container>
        <TopContent>
          <ArrowBack onClick={() => setActivePage("MenuIntroduction")}>
            <svg viewBox="0 0 13 8" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M0.646446 3.64645C0.451184 3.84171 0.451184 4.15829 0.646446 4.35355L3.82843 7.53553C4.02369 7.7308 4.34027 7.7308 4.53553 7.53553C4.7308 7.34027 4.7308 7.02369 4.53553 6.82843L1.70711 4L4.53553 1.17157C4.7308 0.97631 4.7308 0.659728 4.53553 0.464465C4.34027 0.269203 4.02369 0.269203 3.82843 0.464465L0.646446 3.64645ZM13 3.5L1 3.5L1 4.5L13 4.5L13 3.5Z"
                fill="white"
              />
            </svg>
            <span>Voltar</span>
          </ArrowBack>
          <div style={{ width: "89%", textAlign: "center" }}>
            <img
              src={Logo}
              alt="Logo"
              style={{ cursor: "pointer" }}
              onClick={() => setActivePage("MenuIntroduction")}
            />
          </div>
        </TopContent>
        <TextContent>
          <Title>
            <span>Olá!</span>
          </Title>
          <Subtitle>Vamos aprender o nome das letras?</Subtitle>
        </TextContent>
      </Container>
    </>
  );
};

export default Header;
