import React from "react";

import Header from "./components/Header";
import Cards from "./components/Cards";

const MenuIntroduction: React.FC = () => {
  return (
    <>
      <div style={{ overflow: "hidden" }}>
        <Header />
        <Cards />
      </div>
    </>
  );
};

export default MenuIntroduction;
