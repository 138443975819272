import React from "react";

import {
  Container,
  LetterOutlineContent,
  MySvg,
  LetterBox,
  ReloadIcon,
} from "./styles";
import { useLetterOutlineContext } from "../../../../context/LetterOutline";
import {
  A_svg,
  B_Svg,
  C_Svg,
  D_Svg,
  E_Svg,
  F_Svg,
  G_Svg,
  H_Svg,
  I_Svg,
  J_Svg,
  K_Svg,
  L_Svg,
  M_Svg,
  N_Svg,
  O_Svg,
  P_Svg,
  Q_Svg,
  R_Svg,
  S_Svg,
  T_Svg,
  U_Svg,
  V_Svg,
  X_Svg,
  W_Svg,
  Y_Svg,
  Z_Svg,
} from "./Svgs";

const Body: React.FC = () => {
  const [click, setClick] = React.useState("");
  const { letterAux } = useLetterOutlineContext();
  const letterList = [
    { label: "A", svg: A_svg },
    { label: "B", svg: B_Svg },
    { label: "C", svg: C_Svg },
    { label: "D", svg: D_Svg },
    { label: "E", svg: E_Svg },
    { label: "F", svg: F_Svg },
    { label: "G", svg: G_Svg },
    { label: "H", svg: H_Svg },
    { label: "I", svg: I_Svg },
    { label: "J", svg: J_Svg },
    { label: "K", svg: K_Svg },
    { label: "L", svg: L_Svg },
    { label: "M", svg: M_Svg },
    { label: "N", svg: N_Svg },
    { label: "O", svg: O_Svg },
    { label: "P", svg: P_Svg },
    { label: "Q", svg: Q_Svg },
    { label: "R", svg: R_Svg },
    { label: "S", svg: S_Svg },
    { label: "T", svg: T_Svg },
    { label: "U", svg: U_Svg },
    { label: "V", svg: V_Svg },
    { label: "W", svg: W_Svg },
    { label: "X", svg: X_Svg },
    { label: "Y", svg: Y_Svg },
    { label: "Z", svg: Z_Svg },
  ];
  return (
    <Container>
      <LetterOutlineContent>
        <LetterBox>
          <ReloadIcon
            //Ele muda o nome da classe pq tem uma verificação no className de cada svg no arq Svgs.tsx {Comentários no svg da letra A}
            onClick={() => {
              setClick("Restart");
              setTimeout(() => {
                setClick("Another");
              }, 50);
            }}
          >
            <svg version="1.1" viewBox="0 0 489.533 489.533">
              <path
                d="M268.175,488.161c98.2-11,176.9-89.5,188.1-187.7c14.7-128.4-85.1-237.7-210.2-239.1v-57.6c0-3.2-4-4.9-6.7-2.9
		l-118.6,87.1c-2,1.5-2,4.4,0,5.9l118.6,87.1c2.7,2,6.7,0.2,6.7-2.9v-57.5c87.9,1.4,158.3,76.2,152.3,165.6
		c-5.1,76.9-67.8,139.3-144.7,144.2c-81.5,5.2-150.8-53-163.2-130c-2.3-14.3-14.8-24.7-29.2-24.7c-17.9,0-31.9,15.9-29.1,33.6
		C49.575,418.961,150.875,501.261,268.175,488.161z"
              />
            </svg>
          </ReloadIcon>
          <MySvg>
            {letterList.map((information) => {
              return letterAux === information.label ? (
                <>
                  <information.svg key={information.label} nameClass={click} />
                </>
              ) : (
                ""
              );
            })}
          </MySvg>
        </LetterBox>
      </LetterOutlineContent>
    </Container>
  );
};

export default Body;
