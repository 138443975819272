import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  @media (min-width: 1440px) {
    max-width: 1500px;
    margin: 0 auto;
  }
`;
export const TopContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 3%;
  @media (max-width: 768px) {
    padding-top: 10%;
  }
  > div > img {
    width: 200px;
    text-align: center;
  }
`;
export const ArrowBack = styled.div`
  text-align: center;
  padding-left: 1%;
  @media (max-width: 768px) {
    width: 40%;
  }
  > span {
    font-family: "Fira Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    @media (max-width: 500px) {
      font-size: 12px;
    }
    color: var(--fontWhite);
    margin-left: 15px;
    cursor: pointer;
  }
  > svg {
    color: white;
    fill: white;
    cursor: pointer;
    width: 30px;
    height: 13px;
    @media (max-width: 768px) {
      width: 20px;
      height: 9px;
    }
  }
`;

export const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 5%;
  @media (max-width: 768px) {
    margin-top: 15%;
  }
  @media (max-width: 1499px) {
    padding: 0 20px;
  }
`;
export const Title = styled.div`
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  @media (max-width: 768px) {
    font-size: 29px;
  }
  color: var(--fontWhite);
  > span {
    color: var(--primaryGreen);
  }
`;
export const Subtitle = styled.div`
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  @media (max-width: 768px) {
    font-size: 21px;
  }
  color: var(--fontWhite);
  margin-top: 10px;
`;
