import React from "react";
import GlobalStyles from "./Styles/globalStyles";
import MenuIntroduction from "./pages/MenuIntroduction";
import LetterSound from "./pages/LetterSound";
import LetterWriting from "./pages/LetterWriting";
import LetterOutline from "./pages/LetterOutline";
import { useUserPageContext } from "./context/PagesIndicator";

function App() {
  const { activePage } = useUserPageContext();
  return (
    <>
      <GlobalStyles />
      {activePage === "MenuIntroduction" ? <MenuIntroduction /> : ""}
      {activePage === "LetterSound" ? <LetterSound /> : ""}
      {activePage === "LetterWriting" ? <LetterWriting /> : ""}
      {activePage === "LetterOutline" ? <LetterOutline /> : ""}
    </>
  );
}

export default App;
