import styled from "styled-components";

export const Container = styled.div`
  height: 30vh;
  width: 100%;
  position: relative;
  overflow-x: hidden;
`;
export const TopContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 3% 0 0 5%;
  @media (max-width: 768px) {
    padding-top: 10%;
  }
  > div > img {
    width: 200px;
    text-align: center;
  }
`;
export const ArrowBack = styled.div`
  text-align: center;
  padding-left: 1%;
  @media (max-width: 768px) {
    width: 40%;
  }
  > span {
    font-family: "Fira Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    @media (max-width: 768px) {
      font-size: 12px;
    }
    color: var(--fontWhite);
    margin-left: 15px;
    cursor: pointer;
  }
  > svg {
    color: white;
    fill: white;
    cursor: pointer;
    width: 30px;
    height: 13px;
    @media (max-width: 768px) {
      width: 20px;
      height: 9px;
    }
  }
`;

export const NavBar = styled.div`
  margin: 6vh 5% 0;
  overflow-y: hidden;
  overflow-x: auto;
  display: flex;
  padding-bottom: 10px;
  ::-webkit-scrollbar {
    display: none;
  }
`;
export const Letter = styled.div`
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 56px;
  color: var(--darkPink);
  @media (max-width: 768px) {
    font-size: 36px;
    margin: 0 0 1% 4.5%;
  }
  margin: 0 0 1% 2%;
  &.active {
    color: var(--primaryGreen);
    position: relative;
    ::after {
      position: absolute;
      content: "";
      width: 100%;
      height: 4px;
      bottom: -10px;
      left: 0;
      background-color: var(--primaryGreen);
    }
  }
  transition: margin ease 0.5s;
  cursor: pointer;
`;

export const ArrowsContent = styled.div`
  position: absolute;
  right: 15%;
  top: 20%;
  @media (min-width: 1440px) {
    top: 35%;
  }
  display: flex;
  width: 100px;
  justify-content: space-between;
  @media (max-width: 1050px) {
    display: none;
  }
`;
export const LeftArrow = styled.div`
  cursor: pointer;
`;
export const RightArrow = styled.div`
  cursor: pointer;
`;
